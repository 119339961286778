/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { handleSearchTR, modifyTestsArray, renderResultsRow } from "../Tests/helpers";
import { objectCheck } from "../../../ui/helpers";
import { HEADINGS } from "./constants";
import { setResultsList } from "./_redux/actions";
import { Modal } from "../../../ui/components/Modal";
import { useFetch } from "../../../hooks/fetch.hook";
import { ShareScreen } from "./ShareScreen";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { filterDataHelper, searchMap, SEARCH_HELPER } from "../Tests/constants";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { getResults, getResultsAmount, getResultsAutocomplete, getTenantResults } from "./_api";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";
import { getTests, getTestsAutocomplete } from "../Settings/Tests/_api";
import { getCrops, getCropsAutocomplete } from "../Crops/CropsManagement/_api";
import { getSimpleOrganisations } from "../Organisations/_api";
import { TRDetails } from "../../../ui/structures/TRDetails";
import { Share } from "./Share";
import { ShareEmail } from "./Share/ShareEmail";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;
  const tenants = user.tenants || [];

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [resultId, setResultId] = useState("");
  const [focused, setFocused] = useState(false);
  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [reviewedComponentID, setReviewedComponentID] = useState("");

  const [simpleOrganisations, setSimpleOrganisations] = useState([]);
  const [testCodes, setTestCodes] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [shareType, setShareType] = useState("");
  const [shareOpen, setShareOpen] = useState(false);
  const [shareEmailOpen, setShareEmailOpen] = useState(false);

  const handleAddOwner = () => {
    setShareType("owner");
    setOpenModal(false);
    setShareOpen(true);
  };

  const handleAddViewer = () => {
    setShareType("viewer");
    setOpenModal(false);
    setShareOpen(true);
  };

  const handleEmailShare = () => {
    setShareEmailOpen(true);
    setOpenModal(false);
  };

  const handleShareClose = () => setShareOpen(false);
  const handleShareEmailClose = () => setShareEmailOpen(false);
  const handleDetailsModalClose = () => setDetailsModalOpen(false);

  const handleOpenModal = (id) => {
    setOpenModal(true);
    setResultId(id);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setResultId("");
  };

  const data = useSelector(({ results: { resultsList } }) => resultsList);

  const requestResults = () => {
    if (admins_tenant) {
      return request(getTenantResults, page, pageSize, admins_tenant, search, field, field && direction);
    } else return request(getResults, page, pageSize, search, field, field && direction);
  };

  const requestOptions = (autocompleteValue, autocompleteField, searchFields) => {
    if (autocompleteField === "product") {
      return request(getCropsAutocomplete, 80, "name", autocompleteValue);
    } else if (autocompleteField === "test_code") {
      return request(getTestsAutocomplete, 80, "code", autocompleteValue);
    } else
      return request(getResultsAutocomplete, 80, autocompleteField, autocompleteValue, searchFields, "", admins_tenant);
  };

  function fetchResultsAutocomplete(autocompleteValue, autocompleteField, searchFields) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  }

  const fetchResults = () => {
    setLoading(true);
    requestResults()
      .then((data) => {
        if (!data) return;
        dispatch(setResultsList(modifyTestsArray(data, tenants)));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchResults();
  }, [page, pageSize, search, field, direction]);

  useEffect(() => {
    request(getResultsAmount, admins_tenant).then((data) => data && setTotalRecords(data));
  }, [search]);

  useEffect(() => {
    Promise.all([request(getCrops), request(getTests)]).then(([crops, tests]) => {
      crops && setProductsList(crops);
      tests && setTestCodes(tests);
    });
  }, []);

  useEffect(() => {
    request(getSimpleOrganisations).then((data) => data && setSimpleOrganisations(data));
  }, []);

  const resetSearch = () => {
    setSearch("");
    setPage(0);
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      key === "ft_cert" || key === "nata_cert" ? (
        <th key={key} style={sortHeaderStyle} className="text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="text-nowrap"
          onClick={() => {
            setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  const handleExpand = (id) => () => {
    setDetailsModalOpen(true);
    setReviewedComponentID(id);
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        modalContent={
          <ShareScreen
            handleClose={handleCloseModal}
            handleAddOwner={handleAddOwner}
            handleAddViewer={handleAddViewer}
            handleEmailShare={handleEmailShare}
          />
        }
        submitable
      />
      <Modal
        maxWidth="lg"
        isOpen={detailsModalOpen}
        onClose={handleDetailsModalClose}
        modalContent={<TRDetails data={data.find(({ id }) => id === reviewedComponentID)} tenants={tenants} />}
      />
      <Modal
        maxWidth="sm"
        isOpen={shareEmailOpen}
        onClose={handleShareEmailClose}
        submitable
        modalContent={
          <ShareEmail handleClose={handleShareEmailClose} result={data.find(({ id }) => id === resultId)} />
        }
      />
      <Modal
        maxWidth="sm"
        isOpen={shareOpen}
        onClose={handleShareClose}
        submitable
        modalContent={
          <Share shareType={shareType} handleClose={handleShareClose} result={data.find(({ id }) => id === resultId)} />
        }
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearchTR(data, filterDataHelper, setSearch, setPage, simpleOrganisations, productsList, testCodes)
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchResultsAutocomplete}
              fetchFunction={fetchResults}
              placeholder="Search Tests..."
              clearSearch={resetSearch}
              focused={focused}
              setFocused={setFocused}
              searchMap={searchMap}
              searchFields={search}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Results" handleFocus={handleFocus} />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderResultsRow}
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              sharable
              handleshare={handleOpenModal}
              headerClassName="nospaces-header"
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              expandable
              handleExpand={handleExpand}
              expandHeader="Details"
              expandIcon={<MoreHorizIcon />}
            />
          </div>
        </div>
      </div>
    </>
  );
};
