import config from "react-global-configuration";
import { modifyTestFieldSearch } from "../../Tests/helpers";

export const getSamples = (page = 0, page_size = 10, search = "", field = "", direction = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/test_requests?&status=submitted&status=received&page=${page}&page_size=${page_size}${
      search ? "&" + search : ""
    }${(field && "&order_by=" + modifyTestFieldSearch(field)) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTenantSamples = (
  page = 0,
  page_size = 10,
  tenant_id,
  search = "",
  field = "",
  direction = ""
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/test_requests?&status=submitted&status=received&page=${page}&page_size=${page_size}${
      search ? "&" + search : ""
    }${(field && "&order_by=" + modifyTestFieldSearch(field)) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getSamplesAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search,
  tenant_id
) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/test_requests?&status=submitted&status=received&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getSamplesAmount = (tenant_id, search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/test_requests?count_only=y&status=submitted&status=received${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};
