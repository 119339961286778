/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormControlLabel, CircularProgress, TableSortLabel } from "@material-ui/core";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { handleSearchTR, modifyTestsArray, renderTestsRow } from "../Tests/helpers";
import { getSelectedIDs, objectCheck, startDownload } from "../../../ui/helpers";
import { filterDataHelper, searchMap, SEARCH_HELPER } from "../Tests/constants";
import { HEADINGS } from "./constants";
import { removeSample, setSamplesList, updateSamplesList } from "./_redux/actions";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  changeTenantTestStatusMultiple,
  changeTestStatusMultiple,
  generateLabels,
  generateTenantLabels,
} from "../Tests/_api";
import { sortHeaderStyle } from "../../constants";
import { getSamples, getSamplesAmount, getSamplesAutocomplete, getTenantSamples } from "./_api";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";
import { getSimpleOrganisations } from "../Organisations/_api";
import { getCrops, getCropsAutocomplete } from "../Crops/CropsManagement/_api";
import { getTests, getTestsAutocomplete } from "../Settings/Tests/_api";
import { Modal } from "../../../ui/components/Modal";
import { TRDetails } from "../../../ui/structures/TRDetails";

const actionButtonStyle = { marginTop: "0.5rem", minWidth: "100px", minHeight: "36px" };

export const List = () => {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;
  const tenants = user.tenants || [];

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [labelsLoading, setLabelsLoading] = useState(false);
  const [dispatchLoading, setDispatchLoading] = useState(false);
  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [reviewedComponentID, setReviewedComponentID] = useState("");
  const [simpleOrganisations, setSimpleOrganisations] = useState([]);
  const [testCodes, setTestCodes] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleDetailsModalClose = () => setDetailsModalOpen(false);

  const data = useSelector(({ samples: { samplesList } }) => samplesList) || [];

  const requestSamples = () => {
    if (admins_tenant) {
      return request(
        getTenantSamples,
        page,
        pageSize,
        admins_tenant,
        search,
        field,
        field && direction
      );
    } else return request(getSamples, page, pageSize, search, field, field && direction);
  };

  const requestOptions = (autocompleteValue, autocompleteField, searchFields) => {
    if (autocompleteField === "product") {
      return request(getCropsAutocomplete, 80, "name", autocompleteValue);
    } else if (autocompleteField === "test_code") {
      return request(getTestsAutocomplete, 80, "code", autocompleteValue);
    } else
      return request(
        getSamplesAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        searchFields,
        "",
        admins_tenant
      );
  };

  function fetchSamplesAutocomplete(autocompleteValue, autocompleteField, searchFields) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  }

  const fetchSamples = () => {
    setLoading(true);
    requestSamples()
      .then((data) => {
        if (!data) return;
        dispatch(setSamplesList(modifyTestsArray(data, tenants)));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSamples();
  }, [page, pageSize, search, field, direction]);

  useEffect(() => {
    request(getSamplesAmount, admins_tenant).then((data) => data && setTotalRecords(data));
  }, []);

  useEffect(() => {
    Promise.all([request(getCrops), request(getTests)]).then(([crops, tests]) => {
      crops && setProductsList(crops);
      tests && setTestCodes(tests);
    });
  }, []);

  useEffect(() => {
    request(getSimpleOrganisations).then((data) => data && setSimpleOrganisations(data));
  }, []);

  const renderButtons = () => (
    <>
      <div style={actionButtonStyle} className="text-center mr-2">
        <FormControlLabel
          control={
            labelsLoading ? (
              <CircularProgress size="1.2rem" />
            ) : (
              <ListButton
                label="Labels"
                disabled={labelsDisabled}
                onClick={printLabels}
                variant="outlined"
                text="#407A28"
                boxShadow={false}
              />
            )
          }
        />
      </div>
      <div style={actionButtonStyle} className="text-center">
        <FormControlLabel
          control={
            dispatchLoading ? (
              <CircularProgress size="1.2rem" />
            ) : (
              <ListButton
                label="Dispatch"
                disabled={(dispatchDisabled() || []).length === 0}
                onClick={dispatchTests}
                variant="outlined"
                text="#407A28"
                boxShadow={false}
              />
            )
          }
        />
      </div>
    </>
  );

  const resetSearch = () => {
    setSearch("");
    setPage(0);
  };

  const labelsDisabled = useMemo(() => Object.values(selected).filter(Boolean).length === 0, [
    selected,
  ]);

  const filterByStatus = (testIDs, status) => {
    return testIDs.filter(
      (selectedId) => (data.find(({ id }) => selectedId === id) || {}).status === status
    );
  };

  const dispatchDisabled = () => {
    const selectedItems = Object.keys(selected).filter((key) => selected[key]);
    if (filterByStatus(selectedItems, "Submitted").length) {
      return [];
    }
    return filterByStatus(selectedItems, "Received");
  };

  const printLabels = () => {
    const testIDs = getSelectedIDs(selected) || [];
    const submittedTests = filterByStatus(testIDs, "Submitted");
    if (testIDs.length) {
      setLabelsLoading(true);
      request(
        admins_tenant ? generateTenantLabels : generateLabels,
        {
          test_request_ids: testIDs,
          change_status: true,
        },
        admins_tenant
      )
        .then((res) => {
          if (!res) return;
          startDownload(res.url);
          // eslint-disable-next-line
          submittedTests.map((testID) => {
            const sample = data.find(({ id }) => id === testID);
            if (sample)
              dispatch(
                updateSamplesList({
                  ...sample,
                  status: "Received",
                  receive_date: new Date(Date.now()),
                })
              );
          });
        })
        .finally(() => setLabelsLoading(false));
    }
  };

  const dispatchTests = () => {
    const testIDs = dispatchDisabled() || [];
    const editStatusPayload = {
      test_request_ids: testIDs,
      status: "pending_results",
      dispatch_date: new Date(Date.now()),
    };
    if (testIDs.length) {
      setDispatchLoading(true);
      request(
        admins_tenant ? changeTenantTestStatusMultiple : changeTestStatusMultiple,
        editStatusPayload,
        admins_tenant
      )
        .then((res) => {
          if (!res) return;
          testIDs.map((testID) => dispatch(removeSample(testID)));
        })
        .finally(() => setDispatchLoading(false));
    }
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const handleExpand = (id) => () => {
    setDetailsModalOpen(true);
    setReviewedComponentID(id);
  };

  return (
    <>
      {detailsModalOpen && (
        <Modal
          maxWidth="lg"
          isOpen={detailsModalOpen}
          onClose={handleDetailsModalClose}
          modalContent={
            <TRDetails data={data.find(({ id }) => id === reviewedComponentID)} tenants={tenants} />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearchTR(
                  data,
                  filterDataHelper,
                  setSearch,
                  setPage,
                  simpleOrganisations,
                  productsList,
                  testCodes
                )
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchSamplesAutocomplete}
              placeholder="Search Samples..."
              clearSearch={resetSearch}
              focused={focused}
              setFocused={setFocused}
              searchMap={searchMap}
              searchFields={search}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader
              title="Sample Preparation"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderTestsRow}
              selected={selected}
              setSelected={setSelected}
              renderHeaderWithSorting={renderHeaderWithSorting}
              loading={loading}
              selectable
              link="tests"
              headerClassName="nospaces-header"
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              expandable
              handleExpand={handleExpand}
              expandHeader="Details"
              expandIcon={<MoreHorizIcon />}
            />
          </div>
        </div>
      </div>
    </>
  );
};
