import React from "react";

export function isLink(key) {
  return ["trading_name", "first_name", "last_name", "id", "name", "code"].includes(key);
}

export function isArray(key) {
  return ["states"].includes(key);
}

export function isStatus(key) {
  return ["status", "payment_status"].includes(key);
}

export function isDonwloadKey(key) {
  return ["download_pdf"].includes(key);
}

export function isTestCode(key) {
  return ["test_code"].includes(key);
}

export function hasLongContentRow(key) {
  return ["sample_reference", "bill_to", "test_organisation", "site"].includes(key);
}

export function isPrice(key) {
  return [
    "price",
    "cost",
    "sale_price",
    "sales_price",
    "item_price",
    "transaction_total",
    "transaction_tax",
    "amount",
  ].includes(key);
}

export function isDate(key) {
  return [
    "submit_date",
    "receive_date",
    "result_date",
    "start_date",
    "end_date",
    "invoice_date",
  ].includes(key);
}

export function isFTCertificate(key) {
  return ["ft_cert"].includes(key);
}

export function isNATACertificate(key) {
  return ["nata_cert"].includes(key);
}

export function getTip(meta = {}, tip) {
  if (meta.touched && meta.error) {
    return <span className="text-danger">{meta.error}</span>;
  }
  if (tip) {
    return <span className="text-muted">{tip}</span>;
  }
}

export function renderRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {item[key]}
    </td>
  ));
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatDate(date) {
  const d = new Date(date);
  if (isValidDate(d)) {
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1;
    let monthWithNull = month < 10 ? "0" + month : month;
    return day + "/" + monthWithNull + "/" + d.getFullYear();
  }
  console.error("Not a valid Date");
}

export function formatFileName(name) {
  return name
    .split("-")
    .slice(1)
    .join("-");
}

export function sortByDate(a, b) {
  return new Date(b.last_modified) - new Date(a.last_modified);
}

export const onSearch = (search, data, dispatch, action, headings) => {
  dispatch(
    action(
      data.filter((item) => {
        const _search = Object.entries(search).map((item) => [
          (headings.find((i) => i[1] === item[0]) || [])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    )
  );
};

export const getSelectedId = (selected) => {
  for (let key in selected) {
    if (selected[key]) {
      return key;
    }
  }
};

export const getSelectedIDs = (selected) => {
  let selectedItems = [];
  for (let key in selected) {
    if (selected[key]) {
      selectedItems = [...selectedItems, key];
    }
  }
  return selectedItems;
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index + 1),
];

export function formatPrice(price) {
  if (typeof price !== "number") return;
  if (price >= 0) {
    return `$ ${price.toFixed(2)}`;
  } else return `$ (${price.toFixed(2).slice(1)})`;
}

export function formatLimit(limit) {
  if (typeof limit !== "number") return;
  if (limit >= 0) {
    return `${limit.toFixed(2)} %`;
  } else return `(${limit.toFixed(2).slice(1)} %)`;
}

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") delete obj[key];
  });
};

export const findUsersTenant = (userTenants = []) => {
  return Number(
    (Object.entries(userTenants).find((permission) => permission[1].is_tenant_admin) || [])[0]
  );
};

export const getUserRole = (role, tenants = []) => {
  return Object.values(tenants).some((permission) => permission[role]);
};

export const getStatusRowStyle = (item = {}) => {
  if (item.status === "archived") {
    return "text-uppercase text-danger";
  } else return "text-uppercase";
};

const setSearchKey = (data = {}, searchMap, key) => {
  if (key === "Tag") {
    return `tags.name=${data.Tag}`;
  } else if (searchMap[key]) {
    return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
  } else return "";
};

export const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
  setSearch(
    Object.keys(data)
      .map((key) => setSearchKey(data, searchMap, key))
      .join("&")
  );
  setPage && setPage(0);
};

export const startDownload = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};

export function timeDiffCalc(endDate, startDate) {
  const dateFuture = new Date(endDate);
  const dateNow = new Date(startDate);
  let diffInMilliSeconds;

  // console.log(dateFuture, dateNow)

  diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600);
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day, ` : `${days} days, `;
  }

  difference += `${hours}:`;

  difference += minutes < 10 ? `0${minutes}` : minutes;

  return difference;
}

export const handleSort = (data = [], key, field, direction, setDirection) => {
  const newDirection = key !== field ? "desc" : direction === "desc" ? "asc" : "desc";
  setDirection && setDirection(newDirection);
  return data.sort(
    newDirection === "desc"
      ? function(a, b) {
          if (a[key] > b[key]) return 1;
          if (a[key] < b[key]) return -1;
          return 0;
        }
      : function(a, b) {
          if (a[key] > b[key]) return -1;
          if (a[key] < b[key]) return 1;
          return 0;
        }
  );
};

export const sortOnFetch = (data = [], key, direction) => {
  return data.sort(
    direction === "desc"
      ? function(a, b) {
          if (a[key] > b[key]) return 1;
          if (a[key] < b[key]) return -1;
          return 0;
        }
      : function(a, b) {
          if (a[key] > b[key]) return -1;
          if (a[key] < b[key]) return 1;
          return 0;
        }
  );
};

export const objectCheck = (data = []) => {
  return data.every((item) => typeof item !== "object");
};

export const sortHelper = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};
