import { Formik } from "formik";
import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { shareEmailSchema } from "../../../../helpers/schemas";
import { ListButton } from "../../../../ui/components/ListButton";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getIdByEmail } from "../_api";
import { MappedRadio } from "../../../../ui/components/Radio";
import { LinearProgress } from "@material-ui/core";
import { StarHalf as StarHalfIcon } from "@material-ui/icons"

const initialValues = {
  email: "",
  share_type: "",
};

const shareTypeOptions = [
  { name: <div>Share ALL test requests of an organisation <StarHalfIcon className="mb-1" style={{ color: '#50B849'}}/> <span style={{ color: '#50B849' }}>FEATURE COMING SOON</span></div>, id: "multiple", disabled: true },
  { name: "Share only selected test request", id: "single", disabled: false },
];

export const EmailForm = ({ handleNextStep, handleClose, setShareRequest, setEnteredEmail }) => {
  const [loading, setLoading] = useState(false);

  const { request } = useFetch();

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    request(getIdByEmail, values.email)
      .then((data) => {
        if (!data) return;
        setEnteredEmail(values.email);
        setShareRequest(values.share_type);
        handleNextStep(data);
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={shareEmailSchema}>
      {({ handleSubmit, isSubmitting }) => (
        <div className="p-4">
          <h3 className="text-center mb-5">Provide organisation or user email</h3>
          <MappedInput label="Email" name="email" />
          <MappedRadio options={shareTypeOptions} name="share_type" />
          <div className="d-flex justify-content-end mt-10">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="medium"
                onClick={handleClose}
                data-testid="cancel"
                variant="outlined"
                boxShadow={false}
                text="#407A28"
              />
            </div>
            <ListButton
              label="Submit"
              size="medium"
              onClick={handleSubmit}
              boxShadow={false}
              disabled={isSubmitting || loading}
              data-testid="submit"
            />
          </div>
          <div className="py-2">{loading && <LinearProgress />}</div>
        </div>
      )}
    </Formik>
  );
};
