/* eslint-disable no-restricted-imports */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  Typography,
  FormControlLabel,
  CircularProgress,
  Fade,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    marginLeft: 0,
    marginRight: 0
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function ToolTip({
  title,
  description,
  loading,
  content,
  testid,
  placement = "top",
}) {
  const classes = useStyles();

  return (
    <div className="d-flex" data-testid={testid}>
      <FormControlLabel
        classes={classes}
        control={
          loading ? (
            <CircularProgress size="1.2rem" style={{ margin: 11 }} />
          ) : (
            <div>
              <HtmlTooltip
                TransitionComponent={Fade}
                placement={placement}
                title={
                  <React.Fragment>
                    <Typography>{title}</Typography>
                    {description}
                  </React.Fragment>
                }
              >
                {content}
              </HtmlTooltip>
            </div>
          )
        }
      />
    </div>
  );
}
