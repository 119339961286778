import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { Filter } from "../../../../ui/components/Filter";
import { blockMinHeight, buttonClassName, customerFiltersMap, nexStepButtonStyle } from "../constants";
import { filterResults, getKeys } from "../helpers";
import { MappedRadio } from "../../../../ui/components/Radio";
import { customerLookupSchema } from "../../../../helpers/schemas";
import { ListButton } from "../../../../ui/components/ListButton";
import { setData } from "../_redux/actions";
import { CustomerSiteField } from "./CustomerSiteField";

export const CustomerLookup = ({
  data = [],
  handleNextStep,
  customer,
  handlePrevStep,
  isStateAdmin,
  setResetKey,
  simpleLoading,
}) => {
  const { biller_org_id, abn, legal_name, trading_name, biller_site_id } = customer;

  const dispatch = useDispatch();

  const inititalValues = {
    biller_org_id,
    abn,
    legal_name,
    trading_name,
    biller_site_id,
  };

  const getSelectedResult = () => {
    const result = data.find(({ id }) => id === biller_org_id);
    return result && [result];
  };

  const getInitialFilterValue = () => {
    const selectedOption = data.find(({ id }) => biller_org_id === id);
    if (selectedOption) {
      return [
        { ABN: selectedOption.ABN },
        { legal_name: selectedOption.legal_name },
        { trading_name: selectedOption.trading_name },
      ];
    }
  };
  const [searchResults, setSearchResults] = useState(getSelectedResult() || []);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterValue, setFilterValue] = useState(getInitialFilterValue() || []);
  const [siteLoading, setSiteLoading] = useState(false);

  useEffect(() => {
    setFilterOptions(biller_org_id ? getSelectedResult() : data);
    // eslint-disable-next-line
  }, [data]);

  const handleFilter = (filterValue) => {
    const keys = getKeys(filterValue);
    setSearchResults(!keys.length ? [] : filterResults(data, keys, filterValue));
    setFilterOptions(!keys.length ? data : filterResults(data, keys, filterValue));
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "customer");
  };

  const handleReset = (setFieldValue) => () => {
    dispatch(
      setData({
        data: { biller_org_id: "", biller_site_id: "" },
        key: "customer",
      })
    );
    setSearchResults([]);
    setFilterOptions(data);
    setFilterValue([]);
    setFieldValue("biller_org_id", "");
    setResetKey(new Date(Date.now()));
  };

  return (
    <Formik initialValues={inititalValues} onSubmit={handleSubmit} validationSchema={customerLookupSchema}>
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          <div className="row">
            <div className="col-6">
              {simpleLoading ? (
                <Skeleton variant="rect" width={"100%"} height={540} />
              ) : (
                <div className="bg-white p-10" style={blockMinHeight}>
                  <h4 className="mb-10">
                    <strong>Create / Lookup Customer (Bill-To)</strong>
                  </h4>
                  {customerFiltersMap.map(({ name, placeholder }) => (
                    <Filter
                      key={name}
                      name={name}
                      placeholder={placeholder}
                      options={filterOptions}
                      handleFilter={handleFilter}
                      filterValue={filterValue}
                      setFilterValue={setFilterValue}
                      setValue={setFieldValue}
                      defaultValue={values.biller_org_id}
                      openDelay={true}
                      radioButtonName="biller_org_id"
                      isAbnField={name === "ABN"}
                    />
                  ))}
                  <CustomerSiteField loading={siteLoading} setLoading={setSiteLoading} />
                  <div className="text-right mt-5">
                    {handleReset(setFieldValue)}
                    <ListButton
                      label="Reset"
                      variant="outlined"
                      text="#2980c4"
                      boxShadow={false}
                      onClick={handleReset(setFieldValue)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-6">
              <div className="bg-white p-10" style={blockMinHeight}>
                <h4 className="mb-10">
                  <strong>Search Results</strong>
                </h4>
                <div style={{ minHeight: "50px" }}>
                  <MappedRadio options={searchResults} name="biller_org_id" labelName="trading_name" />
                </div>
                <Link
                  to={{
                    pathname: `/add-organisation`,
                  }}
                >
                  <div className="d-flex justify-content-center mt-20 row">
                    <button className={buttonClassName}>Can’t find the organisation? Create</button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-15">
            {isStateAdmin ? (
              <div className="col-6 text-center"></div>
            ) : (
              <div className="col-6 text-center">
                <button className={buttonClassName} onClick={handlePrevStep}>
                  Back
                </button>
              </div>
            )}
            <div className="col-6 text-center">
              <button className={nexStepButtonStyle} onClick={handleSubmit} type="submit" disabled={siteLoading}>
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
