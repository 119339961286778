import { Formik } from "formik";
import React from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { inviteUserSchema } from "../../../../helpers/schemas";
import { ListButton } from "../../../../ui/components/ListButton";
import { LinearProgress } from "@material-ui/core";

export const InviteUser = ({ handleSubmit, handleClose, enteredEmail, loading }) => {
  const initialValues = {
    email_to_invite: enteredEmail || "",
    first_name: "",
    last_name: "",
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={inviteUserSchema}>
      {({ handleSubmit, isSubmitting }) => (
        <div>
          <h4 className="text-center mb-5">
            No organisation or user found with entered email <br /> Provide the details if you want to invite user
          </h4>
          <MappedInput label="First Name" name="first_name" />
          <MappedInput label="Last Name" name="last_name" />
          <MappedInput label="Email" name="email_to_invite" disabled />
          <div className="d-flex justify-content-center mt-10">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="medium"
                onClick={handleClose}
                data-testid="cancel"
                variant="outlined"
                boxShadow={false}
                text="#407A28"
              />
            </div>
            <ListButton
              label="Invite User"
              size="medium"
              onClick={handleSubmit}
              boxShadow={false}
              disabled={isSubmitting}
              data-testid="submit"
            />
          </div>
          <div className="py-2">{loading && <LinearProgress />}</div>
        </div>
      )}
    </Formik>
  );
};
