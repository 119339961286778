import config from "react-global-configuration";

export const addTenant = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getTenants = () =>
  fetch(`${config.get("ApiUrl").Rest}/tenants`, {
    mode: "cors",
    credentials: "include",
  });

export const getTenantById = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyTenant = (data, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteTenant = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getTenantGroups = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/groups`, {
    mode: "cors",
    credentials: "include",
  });
