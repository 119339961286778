import React, { useState } from "react";
import { EmailForm } from "./EmailForm";
import { ShareResult } from "./ShareResult";

export const Share = ({ shareType, handleClose, result = {} }) => {
  const [step, setStep] = useState(0);
  const [idsList, setIdsList] = useState({});
  const [shareRequest, setShareRequest] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");

  const handleNextStep = (data) => {
    setIdsList(data);
    setStep(step + 1);
  };

  const stepsContent = [
    <EmailForm
      handleNextStep={handleNextStep}
      key={0}
      handleClose={handleClose}
      setShareRequest={setShareRequest}
      setEnteredEmail={setEnteredEmail}
    />,
    <ShareResult
      key={1}
      idsList={idsList}
      shareType={shareType}
      handleClose={handleClose}
      shareRequest={shareRequest}
      enteredEmail={enteredEmail}
      result={result}
    />,
  ];

  return <div>{stepsContent.find((item, index) => index === step)}</div>;
};
