import React from "react";
import LensIcon from "@material-ui/icons/Lens";
import { capitalize, get } from "lodash";
import { formatLimit, isStatus } from "../../../ui/helpers";

const green = "#1bcf4b";
const amber = "#f2e018";
const red = "#eb132c";

const getStatusColor = (item = {}) => {
  const MRL = item.MRL;
  const LOR = item.LOR;
  let result = (item.ResultValue || "").replace(`<`, "");
  if(item.AnalyteID === "AN0000760") return green;
  if ((item.ResultValue || "").includes("<") && Number(MRL) === 0) return green;
  if(result !== "0" && Number(MRL) === 0) return red;
  if (item.type === "Heavy Metal" && MRL === "") return green;
  if (result === "ND" && LOR === "ND" && MRL === "D") return green;
  if (
    (result === "D" || typeof result === "number") &&
    LOR === "ND" &&
    MRL === "D"
  )
    return red;
  result = result === "ND" ? 0 : Number(result);
  if ((item.ResultValue || "").includes("<") && result === Number(LOR))
    return green;
  const percentLimit = ((result / Number(MRL)) * 100).toFixed();
  if (!MRL && MRL !== 0) return amber;
  if (typeof result !== "number") return red;
  if (percentLimit < 80) return green;
  else if (percentLimit >= 100) return red;
  else return amber;
};

const renderLimitPercent = (item = {}) => {
  let result = (item.ResultValue || "").replace(`<`, "");
  const MRL = item.MRL;
  const LOR = item.LOR;
  if(item.AnalyteID === "AN0000760") return "";
  if ((item.ResultValue || "").includes("<") && Number(MRL) === 0) return formatLimit(0);
  if(result !== "0" && Number(MRL) === 0) return formatLimit(100);
  if (result === "ND" && LOR === "ND" && MRL === "D") return formatLimit(0);
  if (
    (result === "D" || typeof result === "number") &&
    LOR === "ND" &&
    MRL === "D"
  )
    return formatLimit(100);
  result = result === "ND" ? 0 : Number(result);
  if ((item.ResultValue || "").includes("<") && result === Number(LOR))
    return formatLimit(0);
  if (MRL !== 0 && !MRL) return;
  const value = result / Number(MRL);
  return value === 0 || value ? formatLimit(value * 100) : "Can't calculate";
};

export function renderAnalytesRow(headings, item) {
  return headings.map(([key]) =>
    isStatus(key) ? (
      <td
        key={key}
        className={`no-line border-0 px-5 status`}
        style={{ color: getStatusColor(item) }}
      >
        <LensIcon fontSize="small" className="ml-4" /> {item[key]}
      </td>
    ) : (
      <td key={key} className={`align-middle no-line border-0 px-5 py-5`}>
        {key === "limit_percent" ? renderLimitPercent(item) : item[key]}
      </td>
    )
  );
}

export function modifyResultObject(test = {}, tenants = []) {
  return {
    ...test,
    bill_to: get(test, "biller_org.trading_name", ""),
    test_organisation: get(test, "tester_org.trading_name", ""),
    site: get(test, "test_site.name", ""),
    test_code: get(test, "test.code", ""),
    test_category: get(test, "test.category", ""),
    state: (tenants.find((tenant) => tenant.id === test.tenant_id) || {})
      .physical_state,
    product: get(test, "crop.name", ""),
    status: test.status
      .split("_")
      .map(capitalize)
      .join(" "),
  };
}

export function modifyResultsArray(tests = [], tenants = []) {
  return tests.map((item) => modifyResultObject(item, tenants));
}

const getRandomNumber = (max) => Math.floor(Math.random() * Math.floor(max));

export const setID = (max, data = []) => {
  let random = getRandomNumber(max);
  if (!data.includes(random)) {
    return random;
  } else return setID(max, data);
};
