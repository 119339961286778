import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLaboratory } from "./_redux/selectors";
import { LaboratoryTests } from "./LinkedTests/LaboratoryTests";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getOrganisationById, getOrganisationTags } from "../../Organisations/_api";
import { getLaboratoryTests } from "./_api";
import { modifyOrganisationData } from "../../Organisations/helpers";
import { LaboratoryDetails } from "./LaboratoryDetails";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const data = useSelector(getLaboratory(id));

  const [cardData, setCardData] = useState({});

  const { trading_name, tests } = cardData || "";

  useEffect(() => {
    if (!data) {
      Promise.all([
        request(getOrganisationById, id),
        request(getLaboratoryTests, id),
        request(getOrganisationTags, id),
      ]).then(([organisation, tests, tags]) => {
        if (!organisation || !tests) return;
        setCardData({
          ...modifyOrganisationData(organisation),
          tests,
          tags,
        });
      });
    } else {
      Promise.all([request(getLaboratoryTests, id), request(getOrganisationTags, id)]).then(
        ([tests, tags]) => {
          if (!tags || !tests) return;
          setCardData({
            ...data,
            tests,
            tags,
          });
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const tabs = [
    {
      label: "Profile",
      tabPanel: <LaboratoryDetails name={trading_name} data={cardData} id={id} />,
    },
    {
      label: "Linked Tests",
      tabPanel: (
        <LaboratoryTests
          data={tests}
          name={trading_name}
          laboratoryId={id}
          setCardData={setCardData}
        />
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActive="0" tabs={tabs} align="right" />
    </div>
  );
};
