import React from "react";
import Button from "@bit/the-glue.frontendcomponents.button";

export const ShareScreen = ({ handleClose, handleAddOwner, handleAddViewer, handleEmailShare }) => {
  return (
    <div className="bg-white p-10">
      <h3>
        <strong>Results Sharing Options</strong>
      </h3>
      <div className="my-10">
        FreshTest recommends that you carefully consider your decision before you share your FreshTest results.
      </div>
      <div className="my-10">You can share your results using the following options:</div>
      <div className="my-4">
        <Button
          label="Email"
          size="small"
          background="#2980c4"
          text="#E8F5E1"
          boxShadow
          textTransform="uppercase"
          fontWeight="500"
          fontSize="12px"
          onClick={handleEmailShare}
        />
      </div>
      <div className="mb-10">
        Sharing via this method will send a PDF copy of your results to the email address you specify.
      </div>
      <div className="my-4">
        <Button
          label="Add Owner"
          size="small"
          background="#2980c4"
          text="#E8F5E1"
          boxShadow
          textTransform="uppercase"
          fontWeight="500"
          fontSize="12px"
          onClick={handleAddOwner}
        />
      </div>

      <div className="mb-10">
        Sharing via this method will give the recipient the ability to view, download and print your results as well as
        further share your results with others.
      </div>
      <div className="my-4">
        <Button
          label="Add Viewer"
          size="small"
          background="#2980c4"
          text="#E8F5E1"
          boxShadow
          textTransform="uppercase"
          fontWeight="500"
          fontSize="12px"
          onClick={handleAddViewer}
        />
      </div>
      <div>
        Sharing via this method will share the results via the FreshTest platform. They can view, download and print
        your results but cannot share them further with the FreshTest system.
      </div>
      <div className="d-flex justify-content-end mt-40">
        <Button
          label="cancel"
          onClick={handleClose}
          size="medium"
          background="#2980c4"
          text="#E8F5E1"
          boxShadow
          textTransform="uppercase"
          fontWeight="500"
          fontSize="12px"
        />
      </div>
    </div>
  );
};
