import React, { useState } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { buttonClassName, nexStepButtonStyle } from "../constants";
import { renderCommonTestsRow } from "../helpers";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";

const HEADINGS = [
  ["code", "Test Code"],
  ["name", "Test Name"],
  ["price", "Sale Price(ex GST)"],
];

const headerStyle = {
  background: "#E8F5E1",
  color: "#2196F3",
};

export const TestsList = ({
  data = [],
  handlePrevStep,
  selected,
  setSelected,
  selectedCount,
  handleSubmit,
  isFavorite,
  handleModalOpen,
  setLookaupData,
  hideSelect,
  commonTests = [],
}) => {
  // eslint-disable-next-line
  const [initialData, setInitialData] = useState(data);
  const [direction, setDirection] = useState("desc");

  const onSearch = (search, data) => {
    return data.filter((item) => {
      const _search = Object.entries(search).map((item) => [
        HEADINGS.find((i) => i[1] === item[0])[0],
        item[1],
      ]);
      return (
        _search.length === 0 ||
        _search.every(([k, v]) => {
          if (Array.isArray(item[k])) {
            return item[k].includes(v);
          }
          return item[k] === v;
        })
      );
    });
  };

  const handleSearch = (search, isRemoveKey) => {
    isFavorite
      ? setLookaupData((state) => ({
          ...state,
          commonTests: onSearch(search, isRemoveKey ? initialData : data),
        }))
      : setLookaupData((state) => ({
          ...state,
          unCommonTests: onSearch(search, isRemoveKey ? initialData : data),
        }));
  };

  const KEY_MAP = {
    "Test Code": data.map((item) => item.code),
    "Test Name": data.map((item) => item.name),
  };

  const resetSearch = () => {
    isFavorite
      ? setLookaupData((state) => ({
          ...state,
          commonTests: initialData,
        }))
      : setLookaupData((state) => ({
          ...state,
          unCommonTests: initialData,
        }));
  };

  const handleClose = () => {
    if (isFavorite) {
      setLookaupData((state) => ({
        ...state,
        commonTests: initialData,
      }));
      handlePrevStep();
    } else {
      setLookaupData((state) => ({
        ...state,
        unCommonTests: initialData,
      }));
    }
    handlePrevStep();
  };

  const onSubmit = () => {
    if (isFavorite) {
      setLookaupData((state) => ({
        ...state,
        commonTests: initialData,
      }));
      handleSubmit();
    } else {
      const selectedUncommonTests = data.filter(
        (test) =>
          Object.keys(selected)
            .filter((item) => Boolean(selected[item]))
            .includes(test.id) && !commonTests.find((commonTest) => commonTest.id === test.id)
      );
      setLookaupData((state) => ({
        ...state,
        unCommonTests: initialData,
        commonTests: [...commonTests, ...selectedUncommonTests].sort(
          direction === "desc" ? ascSort : descSort
        ),
      }));
    }
    handleSubmit();
  };

  const ascSort = (a, b) => {
    if (a.code > b.code) return 1;
    if (a.code < b.code) return -1;
    return 0;
  };

  const descSort = (a, b) => {
    if (a.code > b.code) return -1;
    if (a.code < b.code) return 1;
    return 0;
  };

  const handleSort = (data = []) => {
    setDirection(direction === "desc" ? "asc" : "desc");
    data.sort(direction === "desc" ? ascSort : descSort);
  };

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      key === "code" ? (
        <th
          key={key}
          style={sortHeaderStyle}
          className="text-nowrap"
          onClick={() => handleSort(data)}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {<TableSortLabel active direction={direction} />}
        </th>
      ) : (
        <th key={key} style={sortHeaderStyle} className="text-nowrap">
          <span>{header}</span>
        </th>
      )
    );
  }

  return (
    <>
      <div className="bg-white rounded p-10">
        <SearchBar
          onSearch={handleSearch}
          clearSearch={resetSearch}
          keyMap={KEY_MAP}
          placeholder="Filter tests..."
          elevation={2}
          chipBackgroundColor="#E8F5E1"
          chipColor="#255915"
        />
        <h4 className="mb-6">
          <strong>{`Select ${isFavorite ? "Common" : ""} Tests`}</strong>
        </h4>
        <div className="border 1px solid #E5E5E5">
          <div
            className="d-flex justify-content-between p-5 align-items-center"
            style={headerStyle}
          >
            <h6>{`${
              selectedCount > 1 ? `${selectedCount} items` : `${selectedCount} item`
            } selected`}</h6>
          </div>
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderHeaderWithSorting={renderHeaderWithSorting}
            renderRow={renderCommonTestsRow}
            hideSelect={hideSelect}
            selected={selected}
            setSelected={setSelected}
            selectable
            headerClassName="nospaces-header"
          />
        </div>
        {isFavorite && (
          <div className="row mt-5 mb-5">
            <div className="col-6 text-center">
              <button className={buttonClassName} onClick={handleModalOpen}>
                Add Other Test
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-10">
        <div className="col-6 text-center">
          <button className={buttonClassName} onClick={handleClose}>
            Back
          </button>
        </div>
        <div className="col-6 text-center">
          <button className={nexStepButtonStyle} onClick={onSubmit} disabled={!selectedCount}>
            {isFavorite ? "Next" : "Add"}
          </button>
        </div>
      </div>
    </>
  );
};
