import React from "react";
import { TextField } from "@material-ui/core";
import {
  Autocomplete as MuiAutocomplete,
  createFilterOptions,
} from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import get from "lodash/get";

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0.04)",
    paddingLeft: "10px",
  },
});

const filterOptions = createFilterOptions({
  limit: 70,
});

export const Autocomplete = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue,
  defaultValueField = "id",
  disabled,
  label = "label",
  isObjectValue = false,
  variant = "standard",
  defaultStyle,
  ...other
}) => {
  const classes = useStyles();

  const handleChange = (_, value) => {
    setValue(name, get(value, "value", ""));
  };

  const getWholeObject = (_, value) => {
    setValue(name, value);
  };

  return (
    <div>
      <MuiAutocomplete
        options={options}
        id={name}
        filterOptions={filterOptions}
        onChange={isObjectValue ? getWholeObject : handleChange}
        getOptionLabel={(item) => item[label]}
        getOptionSelected={(option, value) => option.value === value.value}
        defaultValue={options.find(
          (item) => item[defaultValueField] === defaultValue
        )}
        disabled={disabled}
        classes={defaultStyle ? {} : classes}
        {...other}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            color="secondary"
            variant={variant}
          />
        )}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
};
