import config from "react-global-configuration";
import { modifyTestFieldSearch } from "../../Tests/helpers";

export const getResults = (page = 0, page_size = 10, search = "", field = "", direction = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/test_requests?&status=plat_review&page=${page}&page_size=${page_size}${
      search ? "&" + search : ""
    }${(field && "&order_by=" + modifyTestFieldSearch(field)) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getResultsAmount = (tenant_id, search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/test_requests?count_only=y&status=plat_review${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};
