import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { ListButton } from "../../../ui/components/ListButton";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const WelcomeOverlay = ({
  onClose,
  isOpen,
  maxWidth = "md",
  ...other
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent>
        <div style={{ minHeight: "40vh" }} className="position-relative">
          <div className="text-center pt-7">
            <img
              className="img-fluid"
              src={toAbsoluteUrl("/assets/icons/Logo.svg")}
              alt="Logo"
            />
          </div>
          <h2 className="text-center py-10">Welcome!</h2>
          <div>
            <div style={{ fontSize: "18px" }}>
              You’ve landed on the all new Govern. If you haven’t used the new
              application yet you’ll need to register for an account. <br />
              The team at Illuminance Labs are sure you’ll love the new
              application but if you have questions you can get in touch by
              phone <span className="text-primary">(07) 111 1111</span> or email{" "}
              <span className="text-primary">email@email.com</span>
            </div>
          </div>
          <div
            className="text-center position-absolute"
            style={{ bottom: 15, left: "45%" }}
          >
            <ListButton label="OK" size="large" onClick={onClose} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
