import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { getSelectedId, handleSearch } from "../../../../ui/helpers";
import { HEADINGS, searchMap } from "./constants";
import { removeCrop, setCropsList, updateCropsList } from "./_redux/actions";
import { renderCropsRow } from "./helpers";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { archiveCrop, getCropById, getCrops, modifyCrop } from "./_api";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { getTags } from "../../Settings/Tags/_api";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [tags, setTags] = useState([]);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(({ crops: { cropsList } }) => cropsList);

  const fetchCrops = () => {
    setLoading(true);
    request(getCrops, search, archived, field, field && direction)
      .then((data) => {
        if (!data) return;
        dispatch(setCropsList(data));
        setInitialData(data);
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCrops();
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  useEffect(() => {
    request(getTags, "type=Contact").then((data) => data && setTags(data));
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    "Crop ID": data.map((item) => item.code),
    "Common Name": data.map((item) => item.name),
    "Botanical Name": data.map((item) => item.botanical_name),
    Tag: tags.map((item) => (item || {}).name),
  };

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveCrop, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getCropById, id).then((data) => data && dispatch(updateCropsList(data)));
        } else dispatch(removeCrop(id));
        setSelected({});
        info("Crop has been archived!");
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const cropID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === cropID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifyCrop, { status: "active" }, cropID)
      .then((data) => {
        if (!data) return;
        dispatch(updateCropsList(data));
        setSelected({});
        info("Crop has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-crop`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
    </>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    dispatch(setCropsList(initialData));
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <>
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).name}
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <SearchBar
              onSearch={(data) => handleSearch(data, searchMap, setSearch)}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter Crops..."
              elevation={2}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
              focused={focused}
              setFocused={setFocused}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Crops" renderButtons={renderButtons} handleFocus={handleFocus} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderCropsRow}
              renderHeaderWithSorting={renderHeaderWithSorting}
              selected={selected}
              setSelected={setSelected}
              editable
              loading={loading}
              selectable
              deletable
              handleDelete={handleOpenModal}
              link="crops"
            />
          </div>
        </div>
      </div>
    </>
  );
};
