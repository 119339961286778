export const HEADINGS = [
  ["code", "Batch Code"],
  ["sample_reference", "Customer Ref"],
  ["test_code", "Test Code"],
  ["bill_to", "Bill to"],
  ["test_organisation", "Test Org"],
  ["site", "Test Site"],
  ["state", "State"],
  ["submit_date", "Submitted"],
  ["receive_date", "Received"],
  ["result_date", "Results"],
  ["status", "Status"],
  ["ft_cert", "FT Cert"],
  ["nata_cert", "NATA Cert"],
];

export const AnalyteHeadings = [
  ["AnalyteID", "Analyte ID"],
  ["name", "Analyte Name"],
  ["ResultValue", "Result Value"],
  ["LOR", "LOR"],
  ["Units", "Units"],
  ["limit_percent", "% of Limit"],
  ["status", "Status"],
];
