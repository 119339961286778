import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { getCrop } from "./_redux/selectors";
import { TagsField } from "../../../../ui/structures/TagsForm/TagsField";
import { Modal } from "../../../../ui/components/Modal";
import { TagsAutocomplete } from "../../../../ui/structures/TagsForm/TagsAutocomplete";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getCropTags, getSpecificCropTags } from "../../Settings/Tags/_api";
import { addCrop, deleteCropTags, editCropTags, getCropById, modifyCrop } from "./_api";
import { CropDetailsSchema } from "../../../../helpers/schemas";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getCrop(id));

  const [cardData, setCardData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const initialValues = {
    code: cardData.code || "",
    name: cardData.name || "",
    botanical_name: cardData.botanical_name || "",
    tags: id ? (cardData.tags || []).map(({ id }) => id) : [],
  };

  useEffect(() => {
    if (id && !data) {
      Promise.all([request(getCropById, id), request(getSpecificCropTags, id)]).then(
        ([details, tags]) => {
          if (!details) return;
          setCardData({ ...details, tags });
        }
      );
    } else if (id) {
      request(getSpecificCropTags, id).then((tags) => {
        setCardData({ ...data, tags });
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const sortTagsHelper = (a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  };

  useEffect(() => {
    request(getCropTags).then((data) => data && setTags(data.sort(sortTagsHelper)));
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  const createCrop = (values, setSubmitting) => {
    setLoading(true);
    const cropPayload = { ...values };
    delete cropPayload.tags;
    request(addCrop, cropPayload)
      .then((data) => {
        if (!data) return;
        if (values.tags.length) {
          return request(
            editCropTags,
            data.id,
            values.tags.map((tagID) => ({ id: tagID }))
          ).finally(() => {
            setSubmitting(false);
            setLoading(false);
          });
        } else return data;
      })
      .then((data) => data && history.push("/crops/management"));
  };

  const editTagsRequest = (values) => {
    if (values.tags.length) {
      return request(
        editCropTags,
        id,
        values.tags.map((tagID) => ({ id: tagID }))
      );
    } else if (!values.tags.length && data.tags.length) {
      return request(deleteCropTags, id, data.tags);
    } else return "success";
  };

  const updateCrop = (values, setSubmitting) => {
    setLoading(true);
    const cropPayload = { ...values };
    delete cropPayload.tags;
    Promise.all([request(modifyCrop, cropPayload, id), editTagsRequest(values)])
      .then(([cropData, tags]) => {
        if (!cropData || !tags) return;
        info("Details have been updated!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    id ? updateCrop(values, setSubmitting) : createCrop(values, setSubmitting);
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <>
      {loading && <Loader isOpen={loading} maxWidth="xs" title="Loading..." />}
      <div className="border border-secondary bg-white p-10">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={CropDetailsSchema}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <>
              {modalOpen && (
                <Modal
                  maxWidth="sm"
                  isOpen={modalOpen}
                  submitable
                  onClose={handleModalClose}
                  modalContent={
                    <TagsAutocomplete
                      name="tags"
                      placeholder="Select Tags"
                      setValue={setFieldValue}
                      options={tags.filter((item) => !values.tags.includes(item.id))}
                      loading={!tags.length}
                      onClose={handleModalClose}
                      currentValue={values.tags}
                      multiple
                    />
                  }
                />
              )}
              <h3 className="mb-10">
                <strong>{id ? cardData.name : "Add Crop"}</strong>
              </h3>
              <div className="row justify-content-between">
                <div className="col-9">
                  {id && (
                    <div className="mb-5">
                      <MappedInput label="Crop ID" name="code" data-testid="code" disabled />
                    </div>
                  )}
                  <div className="mb-5">
                    <MappedInput label="Common Name" name="name" data-testid="name" />
                  </div>
                  <div className="mb-5">
                    <MappedInput
                      label="Botanical Name"
                      name="botanical_name"
                      data-tesid="botanical_name"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <TagsField
                    label="Tags"
                    tags={values.tags}
                    predefinedTags={tags}
                    handleAdd={handleModalOpen}
                    setValue={setFieldValue}
                  />
                </div>
              </div>
              <div className="mt-10">
                <ButtonGroup
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  textTransofrm="uppercase"
                  size="large"
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
