import React, { useState } from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { requestCallSchema } from "../../../helpers/schemas";
import { ListButton } from "../../../ui/components/ListButton";
import { useFetch } from "../../../hooks/fetch.hook";
import { requestCall } from "./_api";
import { Captcha } from "../../../ui/structures/Captcha";

const initialValues = {
  name: "",
  phone_number: "",
  message: "",
};

export const RequestCall = ({ handleClose, setAlertOpen }) => {
  const { request } = useFetch();

  const [captchaOpen, setCaptchaOpen] = useState(false);

  const handleCaptchaSuccess = (values, setSubmitting) => {
    return request(requestCall, values)
      .then((data) => {
        if (!data) return;
        setAlertOpen(true);
        handleClose();
      })
      .finally(() => setSubmitting(false));
  };

  const handleSubmit = () => setCaptchaOpen(true);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={requestCallSchema}
    >
      {({ handleSubmit, isSubmitting, values, setSubmitting }) => (
        <>
          {!captchaOpen && (
            <div className="position-relative">
              <h4 className="mb-10">
                <strong>Request a Call </strong>
              </h4>
              <div className="mb-8">
                <MappedInput name="name" label="Your Name" disabled={captchaOpen} />
              </div>
              <div className="mb-8">
                <MappedInput name="phone_number" label="Your Phone Number" disabled={captchaOpen} />
              </div>
              <div className="mb-8">
                <MappedInput
                  name="message"
                  label=" Your Message"
                  multiline
                  rows={8}
                  disabled={captchaOpen}
                />
              </div>
              <div className="my-5 d-flex justify-content-end mr-4">
                <div className="mr-3">
                  <ListButton
                    label="Cancel"
                    size="large"
                    onClick={handleClose}
                    variant="outlined"
                    text="#2980c4"
                    boxShadow={false}
                  />
                </div>
                <div>
                  <ListButton
                    label="Send"
                    size="large"
                    boxShadow={false}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
            </div>
          )}
          {captchaOpen && (
            <div >
              <Captcha
                onChange={handleCaptchaSuccess}
                values={values}
                setSubmitting={setSubmitting}
              />
            </div>
          )}
        </>
      )}
    </Formik>
  );
};
