export function isLink(key) {
  return ["availability", "cost"].includes(key);
}

export const modifyLaboratories = (data, tenants) =>
  data.map((laboratory) => modifyLaboratoryData(laboratory, tenants));

export const modifyLaboratoryData = (laboratory, tenants) => {
  return {
    ...laboratory,
    availability: laboratory["laboratory_prices"]
      .map(({ tenant_id }) => tenants.find((o) => o.id === tenant_id)["name"])
      .join(", "),
    cost: laboratory["laboratory_prices"]
      .map(({ laboratory_price }) => laboratory_price)
      .join(", "),
  };
};
