import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Select from "@bit/the-glue.frontendcomponents.select";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { HEADINGS } from "./constants";
import {
  removePriceList,
  setPricesList,
  setPricesListFilter,
} from "./_redux/actions";
import { modifyPrices, renderPricesRow } from "./helpers";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  getPrices,
  getPriceLists,
  archivePrice,
  createPriceList,
  updatePriceList,
  deletePriceList,
  modifyPriceListTenants,
  getPriceListTenants,
} from "./_api";
import { getSelectedId } from "../../../../ui/helpers";
import { Modal } from "../../../../ui/components/Modal";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { ManagePricelists } from "../../../../ui/structures/ManagePricelists";
import { info } from "../../../../helpers/toasts";
import { getTenants } from "../../Tenants/_api";
import { setTenantsList } from "../../Tenants/_redux/actions";
import { Loader } from "../../../../ui/components/Loader";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [archiving, setArchiving] = useState(false);
  const [openPricelistModal, setOpenPricelistModal] = useState(false);
  const [filterValue, setFilterValue] = useState(0);
  const [priceLists, setPriceLists] = useState([
    { id: 0, name: "Select Price List" },
  ]);
  const [priceListModalContent, setPriceListModalContent] = useState("");
  const [priceListLoading, setPriceListLoading] = useState(false);
  const [currentListStates, setCurrentListStates] = useState([]);

  const data = useSelector(({ prices: { pricesList } }) => pricesList);
  const tenants =
    useSelector(({ tenants: { tenantsList } }) => tenantsList) || [];

  const handleChange = (event) => {
    dispatch(setPricesListFilter(event.target.value));
    setFilterValue(event.target.value);
  };

  const savedPriceListFilter = useSelector(({ prices: { filter } }) => filter);

  useEffect(() => {
    fetchPriceLists();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filterValue === 0) return;
    setLoading(true);
    request(getPrices, filterValue).then((prices) => {
      if (!prices) {
        setLoading(false);
        return;
      }
      request(getPriceListTenants, filterValue)
        .then((data) => {
          if (!data) return;
          setCurrentListStates(data);
          dispatch(setPricesList(modifyPrices(prices, data)));
        })
        .finally(() => setLoading(false));
    });
    // eslint-disable-next-line
  }, [filterValue]);

  useEffect(() => {
    savedPriceListFilter && setFilterValue(savedPriceListFilter);
    !tenants.length &&
      request(getTenants).then(
        (tenants) => tenants && dispatch(setTenantsList(tenants))
      );
    // eslint-disable-next-line
  }, []);

  const fetchPriceLists = () => {
    request(getPriceLists).then((data) => {
      if (!data) return;
      setPriceLists([{ id: 0, name: "Select Price List" }, ...data]);
    });
  };

  const stateOptions = tenants.map(({ id, name }) => ({ value: String(id), label: name }));

  let priceCodeOptions = useMemo(
    () =>
      priceLists.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [priceLists]
  );

  const handleSubmit = (values, { setSubmitting }) => {
    setPriceListLoading(true);
    const payload = {
      is_master_price_list: values.is_master_price_list,
      name: values.name,
    };
    const states = values.is_master_price_list ? [] : values.states;
    if (priceListModalContent === "new") {
      request(createPriceList, payload)
        .then((data) => {
          if (data) {
            fetchPriceLists();
            info("Price List has been created!");
            request(
              modifyPriceListTenants,
              data.id,
              states.map((tenand_id) => ({ id: tenand_id }))
            );
          }
        })
        .finally(() => {
          setPriceListLoading(false);
        });
    } else if (priceListModalContent === "edit") {
      Promise.all([
        request(updatePriceList, filterValue, payload),
        request(
          modifyPriceListTenants,
          filterValue,
          states.map((tenand_id) => ({ id: tenand_id }))
        ),
      ])
        .then(([details, states]) => {
          if (!details || !states) return;
          info("Price List had been updated!");
          fetchPriceLists();
        })
        .finally(() => {
          setPriceListLoading(false);
        });
    }
    setSubmitting(false);
    setOpenPricelistModal(false);
  };

  const handleDelete = () => {
    request(deletePriceList, filterValue).then((data) => {
      if (data) {
        fetchPriceLists();
        setFilterValue(0);
        info("Deleted successfully!");
      }
    });
  };

  const renderButtons = () => (
    <>
      <div style={{ marginTop: "0.5rem" }} className="col-4">
        <FormControlLabel
          control={
            archiving ? (
              <CircularProgress size="1.2rem" />
            ) : (
              <ListButton
                label="Delete"
                disabled={archiveDisabled}
                onClick={handleArchive}
                boxShadow={false}
                variant="outlined"
                text="#407A28"
                data-testid="archive"
              />
            )
          }
        />
      </div>
      <div className="mr-5">
        <Link
          to={{
            pathname: `/add-price`,
            priceList: filterValue,
          }}
          data-testid="new"
        >
          <ListButton label="Add" />
        </Link>
      </div>
    </>
  );

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleArchive = () => {
    const priceID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === priceID) || {}).status;
    if (status === "archived") return;
    setArchiving(true);
    request(archivePrice, priceID)
      .then((data) => {
        if (!data) return;
        dispatch(removePriceList(priceID));
        info("Price has been archived!");
        setSelected({});
      })
      .finally(() => setArchiving(false));
  };

  return (
    <>
      {openPricelistModal && (
        <Modal
          maxWidth="sm"
          isOpen={openPricelistModal}
          submitable
          onClose={() => setOpenPricelistModal(false)}
          modalContent={
            <ManagePricelists
              onClose={() => setOpenPricelistModal(false)}
              handleSubmit={handleSubmit}
              data={priceLists.find(
                (pricelist) => pricelist.id === filterValue
              )}
              priceListModalContent={priceListModalContent}
              stateOptions={stateOptions}
              currentListStates={currentListStates.map(({ id }) => String(id))}
            />
          }
        />
      )}
      {priceListLoading && (
        <Loader title="Loading..." isOpen={priceListLoading} />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="col-12 bg-white py-1 mb-10 rounded shadow ">
            <div className="d-flex align-item-center">
              <div className="d-flex mt-2">
                <div className="col-10">
                  <Select
                    options={priceCodeOptions}
                    background="transparent"
                    value={filterValue}
                    onChange={handleChange}
                    // style={{ minWidht: 160 }}
                    // className="w-100"
                  />
                </div>
                <IconButton
                  onClick={() => {
                    setPriceListModalContent("edit");
                    setOpenPricelistModal(true);
                  }}
                  style={{ marginLeft: 14 }}
                  disabled={filterValue === 0}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={handleDelete}
                  style={{ marginLeft: 2 }}
                  disabled={filterValue === 0}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div className="col-4 d-flex ml-auto">
                <ListButton
                  className="m-3 w-100"
                  onClick={() => {
                    setPriceListModalContent("new");
                    setOpenPricelistModal(true);
                  }}
                  label="Create new pricelist"
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Sales Prices" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderPricesRow}
              selected={selected}
              setSelected={setSelected}
              editable
              selectable
              loading={loading}
              link="prices"
            />
          </div>
        </div>
      </div>
    </>
  );
};
