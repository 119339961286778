import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getAnalyte } from "./_redux/selectors";
import { groupsList, initialValues, typesList, unitsList } from "./constants";
import { addAnalyte, getAnalyteById, modifyAnalyte } from "./_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { AnalyteDetailsSchema } from "../../../../helpers/schemas";
import { sortHelper } from "../../../../ui/helpers";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const data = useSelector(getAnalyte(id));

  const [cardData, setCardData] = useState({});

  useEffect(() => {
    if (id && !data) {
      request(getAnalyteById, id).then((data) => data && setCardData(data));
    } else {
      setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    request(id ? modifyAnalyte : addAnalyte, values, id)
      .then((data) => data && history.push("/settings/analytes"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={id ? cardData : initialValues}
        validationSchema={AnalyteDetailsSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Analyte"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                {id && <MappedInput label="Analyte ID" name="code" disabled />}
                <MappedInput label="Analyte Name" name="name" data-testid="name" />
                <MappedInput
                  label="Analyte Limit of Reporting Value"
                  name="reporting_value"
                  data-testid="reporting_value"
                />
                <MappedSelect
                  label="Analyte Reporting Unit"
                  options={unitsList.sort(sortHelper)}
                  name="reporting_unit"
                  data-testid="reporting_unit"
                />
                <MappedSelect
                  label="Type"
                  options={typesList.sort(sortHelper)}
                  name="type"
                  data-testid="type"
                />
                <MappedSelect
                  label="Group"
                  options={groupsList.sort(sortHelper)}
                  name="group"
                  data-testid="group"
                />
                <MappedInput
                  label="Common Product Name"
                  name="common_product_name"
                  multiline
                  data-testid="common_product_name"
                  rows={6}
                />
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
