import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { capitalize, get } from "lodash";
import {
  formatDate,
  isDate,
  isStatus,
  isPrice,
  formatPrice,
  isFTCertificate,
  isNATACertificate,
  hasLongContentRow,
  isTestCode,
} from "../../../ui/helpers";
import { ReactComponent as CertificateIcon } from "../../../ui/static/Certificate.svg";
import { downloadFile } from "../AWS-S3";
import { exportCertificate, exportMATACertificate } from "./_api";
import ToolTip from "../../../ui/components/Tooltip";
import { Loader } from "../../../ui/components/Loader";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const handleFTcertificate = (id, setLoading) => {
  setLoading(true);
  downloadFile("", exportCertificate, setLoading, null, id);
};

const handleNATAcertificate = (id, setLoading) => {
  setLoading(true);
  downloadFile(
    "",
    exportMATACertificate,
    setLoading,
    "Your NATA certificate is not currently available for download, please contact the FreshTest office via phone or email to request this document",
    id
  );
};

const ceritifcatesStatuses = ["Completed", "Admin Review", "Plat Review"];

const renderFTCertificate = (item, setLoading) => {
  return ceritifcatesStatuses.includes(item.status || "") ? (
    <>
      <Button data-testid="ft_ceritifcate" onClick={() => handleFTcertificate(item.id, setLoading)}>
        <CertificateIcon />
      </Button>
    </>
  ) : (
    "N/A"
  );
};

const renderNATACertificate = (item, setLoading) => {
  return ceritifcatesStatuses.includes(item.status || "") ? (
    <>
      <Button data-testid="nata_ceritifcate" onClick={() => handleNATAcertificate(item.id, setLoading)}>
        <CertificateIcon />
      </Button>
    </>
  ) : (
    "N/A"
  );
};

const renderInstructions = (item) => {
  return item.special_request ? (
    <>
      <ToolTip
        content={<ErrorOutlineIcon color="primary" />}
        title={item.special_request}
      />
    </>
  ) : null;
};

const formatLongRow = (content = "") => {
  if (content === null) return content;
  if (content.length > 11) {
    return (
      <ToolTip
        content={<div>{content.slice(0, 11) + "..."}</div>}
        title={content}
      />
    );
  } else return content;
};

const formatTestCodeRow = (content = "", category) => {
  if (category) {
    return <ToolTip content={<div>{content}</div>} title={category} />;
  } else return content;
};

export function renderTestsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0  ${isStatus(key) ? "text-uppercase" : ""} ${
        isFTCertificate(key) || isNATACertificate(key) ? "text-center" : ""
      }`}
    >
      {isDate(key)
        ? item[key]
          ? formatDate(new Date(item[key]))
          : "N/A"
        : isPrice(key)
        ? formatPrice(item[key])
        : isStatus(key)
        ? item[key].split("_").join(" ")
        : isFTCertificate(key)
        ? renderFTCertificate(item)
        : isNATACertificate(key)
        ? renderNATACertificate(item)
        : hasLongContentRow(key)
        ? formatLongRow(item[key])
        : isTestCode(key)
        ? formatTestCodeRow(item[key], (item || {}).test_category)
        : key === "special_request"
        ? renderInstructions(item)
        : item[key]}
    </td>
  ));
}

const R = ({ headings, item }) => {
  const [loading, setLoading] = useState(false);
  const handleLoadingClose = () => setLoading(false);
  return (
    <>
      {loading && <Loader title="Downloading..." isOpen={loading} onClose={handleLoadingClose} />}
      {headings.map(([key]) => (
        <td
          key={key}
          className={`align-middle no-line border-0  ${isStatus(key) ? "text-uppercase" : ""} ${
            isFTCertificate(key) || isNATACertificate(key) ? "text-center" : ""
          }`}
        >
          {isDate(key)
            ? item[key]
              ? formatDate(new Date(item[key]))
              : "N/A"
            : isPrice(key)
            ? formatPrice(item[key])
            : isStatus(key)
            ? item[key].split("_").join(" ")
            : isFTCertificate(key)
            ? renderFTCertificate(item, setLoading)
            : isNATACertificate(key)
            ? renderNATACertificate(item, setLoading)
            : hasLongContentRow(key)
            ? formatLongRow(item[key])
            : isTestCode(key)
            ? formatTestCodeRow(item[key], (item || {}).test_category)
            : item[key]}
        </td>
      ))}
    </>
  );
};

export function renderResultsRow(headings, item) {
  return <R headings={headings} item={item} />;
}

export function modifyTestObject(test = {}, tenants = []) {
  return {
    ...test,
    bill_to: get(test, "biller_org.trading_name", ""),
    test_organisation: get(test, "tester_org.trading_name", ""),
    site: get(test, "test_site.name", ""),
    test_code: get(test, "test.code", ""),
    test_category: get(test, "test.category", ""),
    state: (tenants.find((tenant) => tenant.id === test.tenant_id) || {})
      .physical_state,
    product: get(test, "crop.name", ""),
    status: test.status
      .split("_")
      .map(capitalize)
      .join(" "),
  };
}

const fieldNamesMap = {
  bill_to: "biller_org.trading_name",
  test_organisation: "tester_org.trading_name",
  site: "test_site.name",
  test_code: "test.code",
  test_category: "test.category",
  product: "crop.name",
};

export const modifyTestFieldSearch = (field) => {
  const modifiedFields = Object.keys(fieldNamesMap);
  if (modifiedFields.includes(field)) {
    return fieldNamesMap[field];
  } else return field;
};

export function modifyTestsArray(tests = [], tenants = []) {
  return tests.map((item) => modifyTestObject(item, tenants));
}

export const handleSearchTR = (
  data = {},
  filterDataHelper,
  setSearch,
  setPage,
  simpleOrganisations = [],
  productsList = [],
  testCodes = []
) => {
  const modifiedObject = {
    ...data,
    "Bill To": (
      simpleOrganisations.find(
        ({ trading_name }) => trading_name === data["Bill To"]
      ) || {}
    ).id,
    "Test Org": (
      simpleOrganisations.find(
        ({ trading_name }) => trading_name === data["Test Org"]
      ) || {}
    ).id,
    Product: (productsList.find(({ name }) => name === data["Product"]) || {})
      .id,
    "Test Code": (
      testCodes.find(({ code }) => code === data["Test Code"]) || {}
    ).id,
  };
  setSearch(
    Object.keys(modifiedObject)
      .map((key) => {
        if (filterDataHelper[key] && modifiedObject[key]) {
          return `${filterDataHelper[key]}=_like(${encodeURIComponent(
            modifiedObject[key]
          )})_`;
        } else return "";
      })
      .join("&")
  );
  setPage && setPage(0);
};
