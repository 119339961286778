import React, { useState } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Filter } from "../../../ui/components/Filter";
import {
  blockMinHeight,
  buttonClassName,
  nexStepButtonStyle,
  testOrgrFiltersMap,
} from "./constants";
import { filterResults, getKeys } from "./helpers";
import { MappedRadio } from "../../../ui/components/Radio";
import { testOrgLookupSchema } from "../../../helpers/schemas";
import { PopulateTestOrg } from "./PopulateTestOrg";
import { ListButton } from "../../../ui/components/ListButton";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "./_redux/actions";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTests } from "../Settings/Tests/_api";
import { Loader } from "../../../ui/components/Loader";

export const TestOrgLookup = ({
  data = [],
  handleNextStep,
  handlePrevStep,
  testOrganisation,
  customer = {},
  setResetKey,
  tenantId,
  setLookaupData,
}) => {
  const { request } = useFetch();
  const { tester_org_id, abn, legal_name, trading_name } = testOrganisation;

  const billToOrg = useSelector(
    ({
      newTest: {
        data: {
          customer: { biller_org_id },
        },
      },
    }) => biller_org_id
  );

  const dispatch = useDispatch();

  const inititalValues = {
    tester_org_id,
    abn,
    legal_name,
    trading_name,
    as_customer: false,
  };

  const getSelectedResult = () => {
    const result = data.find(({ id }) => id === tester_org_id);
    return result && [result];
  };

  const getInitialFilterValue = () => {
    const selectedOption = data.find(({ id }) => tester_org_id === id);
    if (selectedOption) {
      return [
        { ABN: selectedOption.ABN },
        { legal_name: selectedOption.legal_name },
        { trading_name: selectedOption.trading_name },
      ];
    }
  };

  const [searchResults, setSearchResults] = useState(getSelectedResult() || []);
  const [filterValue, setFilterValue] = useState(getInitialFilterValue() || []);
  const [filterOptions, setFilterOptions] = useState(getSelectedResult() || data);
  const [loading, setLoading] = useState(false);

  const handleFilter = (filterValue) => {
    const keys = getKeys(filterValue);

    setSearchResults(!keys.length ? [] : filterResults(data, keys, filterValue));
    setFilterOptions(!keys.length ? data : filterResults(data, keys, filterValue));
  };

  const modifyTests = (data) => {
    return data.map((item) => {
      if (!item.price) return item;
      return {
        ...item,
        price: (item.price || {}).sales_price,
      };
    });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    request(getTests, `include_price=y&biller_id=${billToOrg}&tenant_id=${tenantId}`)
      .then((data) => {
        if (!data) return;
        setLookaupData((state) => ({
          ...state,
          commonTests: modifyTests(data.filter((item) => item.favourite && item.price)),
          unCommonTests: modifyTests(data.filter((item) => !item.favourite && item.price)),
        }));
        handleNextStep(values, "testOrganisation");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = (setFieldValue) => () => {
    dispatch(setData({ data: { tester_org_id: "" }, key: "testOrganisation" }));
    setSearchResults([]);
    setFilterOptions(data);
    setFilterValue([]);
    setFieldValue("tester_org_id", "");
    setFieldValue("as_customer", false);
    setResetKey(new Date(Date.now()));
  };

  return (
    <Formik
      initialValues={inititalValues}
      onSubmit={handleSubmit}
      validationSchema={testOrgLookupSchema}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          {loading && <Loader title="Loading..." isOpen={loading} />}
          <div className="row">
            <div className="col-6">
              <div className="bg-white p-10" style={blockMinHeight}>
                <h4 className="mb-10">
                  <strong>Create / Lookup Customer (Testing Org)</strong>
                </h4>
                {testOrgrFiltersMap.map(({ name, placeholder }) => (
                  <Filter
                    key={name}
                    name={name}
                    placeholder={placeholder}
                    options={filterOptions}
                    handleFilter={handleFilter}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    setValue={setFieldValue}
                    defaultValue={values.tester_org_id}
                    openDelay={true}
                    radioButtonName="tester_org_id"
                    isAbnField={name === "ABN"}
                  />
                ))}
                <div className="text-right mt-5">
                  <ListButton
                    label="Reset"
                    variant="outlined"
                    text="#2980c4"
                    boxShadow={false}
                    onClick={handleReset(setFieldValue)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="bg-white p-10" style={blockMinHeight}>
                <h4 className="mb-10">
                  <strong>Search Results</strong>
                </h4>
                <div style={{ minHeight: "50px" }}>
                  <MappedRadio
                    options={searchResults}
                    name="tester_org_id"
                    labelName="trading_name"
                  />
                </div>
                {!tester_org_id && (
                  <PopulateTestOrg
                    data={data}
                    customer={customer}
                    setFilterOptions={setFilterOptions}
                    setFilterValue={setFilterValue}
                    setSearchResults={setSearchResults}
                    tester_org_id={tester_org_id}
                  />
                )}

                <Link
                  to={{
                    pathname: `/add-organisation`,
                  }}
                >
                  <div className="d-flex justify-content-center mt-20 row">
                    <button className={buttonClassName}>Can’t find the organisation? Create</button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-15">
            <div className="col-6 text-center">
              <button className={buttonClassName} onClick={handlePrevStep}>
                Back
              </button>
            </div>
            <div className="col-6 text-center">
              <button className={nexStepButtonStyle} onClick={handleSubmit} type="submit">
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
