import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@bit/the-glue.frontendcomponents.button";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { modifyTestsArray, renderTestsRow } from "../Tests/helpers";
import { getTestRequests } from "../Tests/_api";
import { onSearch } from "../../../ui/helpers";
import { HEADINGS } from "./constants";
import { setData } from "./_redux/actions";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import { DevAlertBar } from "../../../ui/components/AlertBar";
import { getTenants } from "../Tenants/_api";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [initialData, setInitialData] = useState([]);

  const data = useSelector(({ invoiceReconciliation: { testsList } }) => testsList);

  const fetchTestRequests = () => {
    setLoading(true);
    request(getTenants).then(
      (tenants) =>
        tenants &&
        request(getTestRequests)
          .then((data) => {
            if (!data) return;
            return modifyTestsArray(data, tenants);
          })
          .then((data) => {
            if (!data) return;
            setInitialData(data);
            dispatch(setData(data.filter((item) => item.status === "Completed")));
            setLoading(false);
          })
    );
  };

  useEffect(() => {
    fetchTestRequests();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search, isRemoveKey) => {
    onSearch(search, isRemoveKey ? initialData : data, dispatch, setData, HEADINGS);
  };

  const KEY_MAP = {
    "Batch Code": data.map((item) => item.code),
    State: data.map((item) => item.state),
    "Bill to": data.map((item) => item.bill_to),
    "Test Org": data.map((item) => item.test_organisation),
    Status: data.map((item) => item.status),
  };

  const resetSearch = () => {
    setLoading(true);
    request(getTestRequests).then((data) => {
      if (!data) return;
      setInitialData(data);
      dispatch(setData(data));
      setLoading(false);
    });
  };

  const handleInvoicing = () => console.log("invoiced...");

  const handleSelect = () => {
    const selectedIds = Object.keys(selected).filter((item) => Boolean(selected[item]));
    const selectedTests = data.filter((item) => selectedIds.includes(item.id));
    return selectedTests.reduce((acc, value) => acc + value.cost, 0).toFixed(2);
  };

  const handleFocus = () => setFocused(true);

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        <DevAlertBar message="This Page is Under Development" />
        <div>
          <SearchBar
            onSearch={handleSearch}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
            defaultValue={[{ key: "Status", value: "Completed" }]}
            defaultKey="Status"
            focused={focused}
            setFocused={setFocused}
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Invoice Reconciliation" handleFocus={handleFocus} />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderTestsRow}
            selected={selected}
            setSelected={setSelected}
            loading={loading}
            selectable
            headerClassName="nospaces-header"
          />
          <div className="d-flex justify-content-between mt-15">
            <h4>
              <strong>{`Selection Total : $ ${handleSelect()}`}</strong>
            </h4>
            <div>
              <Button
                label="mark selection as invoiced"
                size="small"
                text="#599D36"
                background="light"
                boxShadow
                textTransform="uppercase"
                fontWeight="600"
                onClick={handleInvoicing}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
