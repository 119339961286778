import React, { useState } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { AnalyteHeadings } from "../ResultsReview/constants";
import { renderAnalytesRow } from "../ResultsReview/helpers";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { ListButton } from "../../../ui/components/ListButton";
import { useFetch } from "../../../hooks/fetch.hook";
import { modifyTestRequest } from "../Tests/_api";
import { info } from "../../../helpers/toasts";
import { updateResultsList } from "./_redux/actions";
import { modifyTestObject } from "../Tests/helpers";
import { useDispatch } from "react-redux";
import { Loader } from "../../../ui/components/Loader";

export const AnalytesList = ({ data = [], setAnalytesData, selectedResult, tenants = [] }) => {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState({});
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);

  const ascSort = (data = [], key) => {
    data.sort(function(a, b) {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  };

  const descSort = (data = [], key) => {
    data.sort(function(a, b) {
      if (a[key] > b[key]) return -1;
      if (a[key] < b[key]) return 1;
      return 0;
    });
  };

  const handleSort = (data = [], key) => {
    setDirection((state) => {
      setField(key);
      state === "desc" ? ascSort(data, key) : descSort(data, key);
      return key !== field ? "desc" : direction === "desc" ? "asc" : "desc";
    });
  };

  const sortFields = ["AnalyteID", "name"];

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) =>
      sortFields.includes(key) ? (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => handleSort(data, key)}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      ) : (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      )
    );
  }

  const handleApproveLimits = () => {
    setLoading(true);
    const payload = {
      results: data,
      status: "plat_review",
    };
    request(modifyTestRequest, payload, selectedResult)
      .then((data) => {
        if (!data) return;
        dispatch(updateResultsList(modifyTestObject(data, tenants)));
        info("Limits have been updated!");
      })
      .finally(() => setLoading(false));
  };

  const handleCellBlur = (value, result = {}) => {
    const index = data.findIndex((item) => (item || {}).id === (result || {}).id);
    const modifiedResults = [
      ...data.slice(0, index),
      { ...result, MRL: value },
      ...data.slice(index + 1),
    ];
    setAnalytesData(modifiedResults);
  };

  const renderButtons = () => {
    return (
      <div>
        <ListButton label="Submit Limits" onClick={handleApproveLimits} disabled={loading} />
      </div>
    );
  };

  return (
    <>
      {loading && <Loader title="Loading..." isOpen={loading} />}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Results" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={AnalyteHeadings}
              renderRow={renderAnalytesRow}
              selectable
              selected={selected}
              setSelected={setSelected}
              renderHeaderWithSorting={renderHeaderWithSorting}
              hideSelect
              paginationDefaultValue={100000}
              editableCell
              editableCellName="MRL"
              editableCellHeader="Limit"
              handleCellBlur={handleCellBlur}
            />
          </div>
        </div>
      </div>
    </>
  );
};
