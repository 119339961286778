import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTenantsList } from "../../../Tenants/_redux/actions";

import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { Edit as EditIcon, Add as AddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

import { useFetch } from "../../../../../hooks/fetch.hook";
import { unlinkTests, getLaboratoryTests } from "../_api";
import { getTenants } from "../../../Tenants/_api";

import { getStatusRowStyle, isStatus } from "../../../../../ui/helpers";
import { ListButton } from "../../../../../ui/components/ListButton";
import { ListHeader } from "../../../../../ui/structures/ListHeader";
import { Modal } from "../../../../../ui/components/Modal";
import { LaboratoryTestPricesModal } from "./LaboratoryTestPricesModal";

import { TestsList } from "./TestsList";
import { isLink, modifyLaboratories } from "./helpers";

const useStyles = makeStyles({
  links: {
    display: "flex",
    padding: "15.25px 1.25rem",
    height: 50,
    transition: "0.1s ease-out",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0,0,0,0.1)",
      "& $edit": {
        opacity: 1,
      },
      "& $add": {
        opacity: 0,
      },
    },
  },
  tableIcon: {
    marginLeft: "auto",
  },
  edit: {
    opacity: 0,
    transition: "0.1s ease-out",
  },
  add: {
    position: "absolute",
    opacity: 0.8,
    transition: "0.1s ease-out",
  },
});

const HEADINGS = [
  ["code", "Test ID"],
  ["name", "Test Name"],
  ["availability", "Availability"],
  ["cost", "Cost"],
  ["status", "Status"],
];

export const LaboratoryTests = ({
  data = [],
  name,
  laboratoryId,
  setCardData,
}) => {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const classes = useStyles();

  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [linkedTests, setLinkedTests] = useState(data);
  const [nonLinkedTests, setNonLinkedTests] = useState([]);
  const [laboratoryTestPriceIsOpen, setLaboratoryTestPriceIsOpen] = useState(
    false
  );
  const [laboratoryTestPriceId, setLaboratoryTestPriceId] = useState("");

  const tenants =
    useSelector(({ tenants: { tenantsList } }) => tenantsList) || [];

  const handleFocus = () => setFocused(true);

  const handleModalOpen = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
    setSelected({});
  };

  const fetchLaboratories = () => {
    setLoading(true);
    request(getLaboratoryTests, laboratoryId)
      .then((data) => {
        if (!data) return;
        setLinkedTests(modifyLaboratories(data, tenants));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    !tenants.length &&
      request(getTenants).then(
        (tenants) => tenants && dispatch(setTenantsList(tenants))
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    tenants.length && fetchLaboratories();
    // eslint-disable-next-line
  }, [tenants]);

  const handleSearch = (search, isRemoveKey) => {
    setCardData((state) => ({
      ...state,
      tests: (isRemoveKey ? linkedTests : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          HEADINGS.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      }),
    }));
  };

  const KEY_MAP = {
    "Test ID": data.map((item) => item.code),
    "Test Name": data.map((item) => item.name),
    Status: data.map((item) => item.status),
  };

  const resetSearch = () => {
    setCardData((state) => ({
      ...state,
      tests: linkedTests,
    }));
  };

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton label="Add Test" onClick={handleModalOpen} />
      </div>
      <div className="mr-5">
        <ListButton
          label="Remove Test"
          onClick={handleRemoveTest}
          disabled={unlinkDisabled}
        />
      </div>
    </>
  );

  const unlinkDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const updateTestsList = () => {
    setLoading(true);
    return request(getLaboratoryTests, laboratoryId)
      .then((data) => {
        if (!data) return;
        setCardData((state) => ({
          ...state,
          tests: modifyLaboratories(data, tenants),
        }));
        setLinkedTests(modifyLaboratories(data, tenants));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveTest = () => {
    setLoading(true);
    const payload = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({
        id: item,
      }));
    request(unlinkTests, payload, laboratoryId).then((ids) => {
      if (!ids) {
        setLoading(false);
        return;
      }
      updateTestsList();
      setSelected({});
    });
  };

  const laboratoryTestPriceModalClose = () =>
    setLaboratoryTestPriceIsOpen(false);

  function renderLinkedTestsRow(headings, item) {
    return headings.map(([key]) => (
      <td
        key={key}
        className={`align-middle no-line border-0 ${!isLink(key) &&
          "px-5 py-5"} ${isStatus(key) && getStatusRowStyle(item)}`}
      >
        {isLink(key) ? (
          <div className={classes.links} onClick={() => handleExpand(item.id)}>
            {item[key]}{" "}
            <div className={classes.tableIcon}>
              <div className={classes.add}>
                <AddIcon fontSize="small" />
              </div>
              <div className={classes.edit}>
                <EditIcon fontSize="small" />
              </div>
            </div>
          </div>
        ) : (
          item[key]
        )}
      </td>
    ));
  }

  const handleExpand = (id) => {
    setLaboratoryTestPriceIsOpen(true);
    setLaboratoryTestPriceId(id);
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleClose}
          modalContent={
            <TestsList
              handleClose={handleClose}
              data={nonLinkedTests}
              laboratoryId={laboratoryId}
              setNonLinkedTests={setNonLinkedTests}
              setLoading={setLoading}
              updateTestsList={updateTestsList}
            />
          }
        />
      )}
      {laboratoryTestPriceIsOpen && (
        <Modal
          maxWidth="lg"
          isOpen={laboratoryTestPriceIsOpen}
          onClose={laboratoryTestPriceModalClose}
          buttonText="CLOSE"
          modalContent={
            <LaboratoryTestPricesModal
              test_data={data.find(({ id }) => id === laboratoryTestPriceId)}
              laboratory_id={laboratoryId}
              updateTestsList={updateTestsList}
              tenants={tenants}
            />
          }
        />
      )}
      <div className="row justify-content-center bg-white py-15 px-10">
        <div className="col-12">
          <h3 className="mb-15">
            <strong>{name}</strong>
          </h3>
          <div className="mb-10">
            <SearchBar
              onSearch={handleSearch}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter Tests..."
              elevation={2}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
              focused={focused}
              setFocused={setFocused}
            />
          </div>
          <div>
            <ListHeader
              renderButtons={renderButtons}
              handleFocus={handleFocus}
              loading={loading}
            />
            <Datagrid
              data={linkedTests}
              headings={HEADINGS}
              renderRow={renderLinkedTestsRow}
              editable
              selected={selected}
              setSelected={setSelected}
              selectable
              link="tests"
            />
          </div>
        </div>
      </div>
    </>
  );
};
