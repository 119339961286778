import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Skeleton from "@material-ui/lab/Skeleton";
import { Filter } from "../../../ui/components/Filter";
import { blockMinHeight, buttonClassName, nexStepButtonStyle, siteFiltersMap } from "./constants";
import { filterResults, getKeys } from "./helpers";
import { MappedRadio } from "../../../ui/components/Radio";
import { siteLookupSchema } from "../../../helpers/schemas";
import { useFetch } from "../../../hooks/fetch.hook";
import { getOrganisationSites } from "../Organisations/_api";
import { SiteCard } from "../../submodules/Sites/SiteCard";

export const SiteLookup = ({ testOrganisation, handleNextStep, handlePrevStep, site }) => {
  const { test_site_id, name, address } = site;
  const { request } = useFetch();

  const inititalValues = {
    test_site_id,
    name,
    address,
  };
  const [searchResults, setSearchResults] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("pick");
  const [data, setData] = useState([]);

  const handleFilter = (filterValue) => {
    const keys = getKeys(filterValue);

    setSearchResults(!keys.length ? [] : filterResults(filterOptions, keys, filterValue));
    setFilterOptions(!keys.length ? data : filterResults(data, keys, filterValue));
  };

  useEffect(() => {
    setLoading(true);
    if (testOrganisation && testOrganisation.tester_org_id) {
      request(getOrganisationSites, testOrganisation.tester_org_id)
        .then((data) => {
          if (!data) return;
          setFilterOptions(data);
          setData(data);
          const selectedOption = data.find(({ id }) => id === test_site_id);
          if (!selectedOption) return;
          setSearchResults([selectedOption]);
          setFilterValue([
            { name: selectedOption.name },
            { physical_address: selectedOption.physical_address },
          ]);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [testOrganisation]);

  const handleSubmit = (values) => {
    handleNextStep(values, "site");
  };

  const handleSkip = (setTouched) => {
    setTouched(["test_site_id"], false);
    handleNextStep({ test_site_id: "" }, "site");
  };

  const handleCancel = () => setContent("pick");

  const openSiteForm = () => setContent("add");

  if (loading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <>
      {content === "pick" ? (
        <Formik
          initialValues={inititalValues}
          onSubmit={handleSubmit}
          validationSchema={siteLookupSchema}
        >
          {({ handleSubmit, setTouched, setFieldValue, values }) => (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="bg-white p-10" style={blockMinHeight}>
                    <h4 className="mb-10">
                      <strong>Create / Lookup Customer Farm Site</strong>
                    </h4>
                    {siteFiltersMap.map(({ name, placeholder }) => (
                      <Filter
                        key={name}
                        name={name}
                        placeholder={placeholder}
                        options={filterOptions}
                        handleFilter={handleFilter}
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                        setValue={setFieldValue}
                        defaultValue={values.test_site_id}
                        radioButtonName="test_site_id"
                      />
                    ))}
                  </div>
                </div>
                <div className="col-6">
                  <div className="bg-white p-10" style={blockMinHeight}>
                    <h4 className="mb-10">
                      <strong>Search Results</strong>
                    </h4>
                    <div style={{ minHeight: "50px" }}>
                      <MappedRadio options={searchResults} name="test_site_id" />
                    </div>
                    <div className="mt-20 row">
                      <div className="col-12 mb-5 text-center">
                        <button className={buttonClassName} onClick={() => handleSkip(setTouched)}>
                          Skip
                        </button>
                      </div>
                      <div className="col-12 text-center">
                        <button className={buttonClassName} onClick={openSiteForm}>
                          Can’t find the site? Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-15">
                <div className="col-6 text-center">
                  <button className={buttonClassName} onClick={handlePrevStep}>
                    Back
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button className={nexStepButtonStyle} onClick={handleSubmit} type="submit">
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </Formik>
      ) : (
        <SiteCard
          organisation_id={testOrganisation && testOrganisation.tester_org_id}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};
