import config from "react-global-configuration";
import { modifyTestFieldSearch } from "../../Tests/helpers";

export const getResults = (page = 0, page_size = 10, search = "", field = "", direction = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/test_requests?&status=completed&page=${page}&page_size=${page_size}${
      search ? "&" + search : ""
    }${(field && "&order_by=" + modifyTestFieldSearch(field)) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTenantResults = (page = 0, page_size = 10, tenant_id, search = "", field = "", direction = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/test_requests?&status=completed&page=${page}&page_size=${page_size}${
      search ? "&" + search : ""
    }${(field && "&order_by=" + modifyTestFieldSearch(field)) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getResultsAutocomplete = (page_size = 80, autocompleteField, autocompleteText = "", search, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/test_requests?&status=completed&page_size=${page_size}${(autocompleteField &&
      "&distinct=" + autocompleteField + "&" + autocompleteField + "=_like(" + autocompleteText + ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getResultsAmount = (tenant_id, search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}${
      tenant_id ? `/tenants/${tenant_id}` : ""
    }/test_requests?count_only=y&status=completed${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getIdByEmail = (email = "") => {
  return fetch(`${config.get("ApiUrl").Rest}/user-and-organisation-ids-by-email?email=${email}`, {
    mode: "cors",
    credentials: "include",
  });
};

export const shareAllResults = (data, organisation_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/test_requests/share`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const shareResult = (data, test_request_id) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/${test_request_id}/share`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const shareCertificate = (data, test_request_id) => {
  return fetch(`${config.get("ApiUrl").Rest}/test_requests/${test_request_id}/certificate/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
};

export const shareInvoice = (data, invoice_id) =>
  fetch(`${config.get("ApiUrl").Rest}/invoices/${invoice_id}/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
