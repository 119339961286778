/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { AccessControl } from "../../../../../ui/structures/AccessControl";
import { ITEM_TYPES, END_USER_MENU, STATE_MENU, NATIONAL_MENU, GUEST_MENU, PLATFORM_MENU } from "./constants";
import { AVAILABLE_ROLES } from "../../../../../app/constants";
import "./AsideColorFix.css";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector(({ auth: { user } }) => user) || {};
  const isPlatformAdmin = user.groups.find((group) => (group || {}).type === "platform_admin");

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <AccessControl desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}>
          {(isPlatformAdmin ? PLATFORM_MENU : NATIONAL_MENU).map((item, index) => <MenuItem key={index} item={item} />)}
        </AccessControl>
        <AccessControl desiredRoll={AVAILABLE_ROLES.TENANT_ADMIN}>
          {STATE_MENU.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </AccessControl>
        <AccessControl desiredRoll={AVAILABLE_ROLES.END_USER}>
          {END_USER_MENU.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </AccessControl>
        <AccessControl desiredRoll={AVAILABLE_ROLES.TENANT_GUEST}>
          {GUEST_MENU.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </AccessControl>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

function MenuItem({ item, subMenu }) {
  const location = useLocation();
  const getMenuItemActive = useCallback(
    (url) => {
      return checkIsActive(location, url) ? "menu-item-active menu-item-open" : "";
    },
    [location]
  );

  const getMenuItemTextActive = useCallback(
    (url) => {
      return checkIsActive(location, url) ? "text-active " : "";
    },
    [location]
  );

  const getMenuBackgroundActive = useCallback(
    (url) => {
      return checkIsActive(location, url) ? "bg-active" : "";
    },
    [location]
  );

  const { type } = item;
  switch (type) {
    case ITEM_TYPES.link:
      return (
        <li className={`menu-item ${getMenuItemActive(item.to)}`}>
          <NavLink className={`menu-link ${getMenuBackgroundActive(item.to)}`} to={item.to}>
            {Boolean(subMenu) && (
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
            )}
            {Boolean(item.icon) && <span className="svg-icon menu-icon">{item.icon}</span>}
            <span className={`menu-text ${getMenuItemTextActive(item.to)}`}>{item.title}</span>
          </NavLink>
        </li>
      );
    case ITEM_TYPES.heading:
      return (
        <li className="menu-item" style={{ pointerEvents: "none" }}>
          <div className="menu-link">
            <span className="menu-text font-weight-bold" style={{ color: "#464E5F", cursor: "default" }}>
              {item.title}
            </span>
          </div>
        </li>
      );
    case ITEM_TYPES.menu:
      return (
        <li className={`menu-item menu-item-submenu ${getMenuItemActive(item.to)}`} data-menu-toggle="hover">
          <NavLink className={`menu-link menu-toggle ${getMenuBackgroundActive(item.to)}`} to={item.to}>
            <span className="svg-icon menu-icon">{item.icon}</span>
            <span className={`menu-text ${getMenuItemTextActive(item.to)}`}>{item.title}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent">
                <span className="menu-link">
                  <span className={`menu-text ${getMenuItemTextActive(item.to)}`}>{item.title}</span>
                </span>
              </li>
              {item.items.map((subItem, index) => (
                <MenuItem key={index} item={subItem} subMenu />
              ))}
            </ul>
          </div>
        </li>
      );
    default:
      return null;
  }
}
