import React from "react";
import { Formik } from "formik";
import Input, { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { buttonClassName, nexStepButtonStyle } from "./constants";

export const AdditionalInfo = ({
  handleNextStep,
  handlePrevStep,
  additionalInfo,
  tenants = [],
  selectedTenant = {},
}) => {
  const { sample_reference, special_request } = additionalInfo;

  const inititalValues = {
    sample_reference,
    special_request,
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "additionalInfo");
  };
  const deliveryDetails = (tenants.find(({ id }) => id === selectedTenant.tenant_id) || {}).delivery_instruction;

  return (
    <Formik onSubmit={handleSubmit} initialValues={inititalValues}>
      {({ handleSubmit }) => (
        <>
          <div className="bg-white p-10">
            <h4 className="mb-10">
              <strong>Additonal Information</strong>
            </h4>
            <div className="d-flex justfiy-content-between">
              <div className="col-6">
                <div className="mb-10">
                  <MappedInput name="sample_reference" label="Add your sample reference here." />
                </div>
                <div className="mb-10">
                  <MappedInput name="special_request" label="Special instructions" multiline rows={6} />
                </div>
              </div>
              <div className="col-6">
                <Input
                  label="Delivery Details"
                  data-testid="delivery_instruction"
                  multiline
                  rows={12}
                  disabled
                  value={deliveryDetails}
                />
              </div>
            </div>
            <div className="pb-15">
              <h3 className="text-uppercase">important </h3>
              <p>
                If this test is being carried out for the purpose of challenge testing or any other purpose other than
                normal product surveillance and assessment please contact FreshTest on 07 3915 4222 prior to submitting
                product for analysis.
              </p>
            </div>
          </div>
          <div className="row mt-15">
            <div className="col-6 text-center">
              <button className={buttonClassName} onClick={handlePrevStep}>
                Back
              </button>
            </div>
            <div className="col-6 text-center">
              <button className={nexStepButtonStyle} onClick={handleSubmit} type="submit">
                Proceed to Payment
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
