import { Formik } from "formik";
import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { shareCertificateSchema } from "../../../../helpers/schemas";
import { ListButton } from "../../../../ui/components/ListButton";
import { useFetch } from "../../../../hooks/fetch.hook";
import { LinearProgress } from "@material-ui/core";
import { info } from "../../../../helpers/toasts";
import { shareInvoice, shareCertificate } from "../_api";

const initialValues = {
  email: "",
};

export const ShareEmail = ({ handleClose, result = {} }) => {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);

  const handleShareCertificate = (values, { setSubmitting }) => {
    request(shareCertificate, { email: values.email }, result.id)
      .then((data) => {
        if (!data) return;
        info("Certificate has been shared!");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleShareInvoice = (values, setSubmitting) => {
    setLoading(true);
    request(shareInvoice, { email: values.email }, result.invoice_id)
      .then((data) => {
        if (!data) return;
        info("Invoice has been shared!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };
  return (
    <Formik onSubmit={handleShareCertificate} initialValues={initialValues} validationSchema={shareCertificateSchema}>
      {({ handleSubmit, isSubmitting, values, setSubmitting }) => (
        <div className="p-4">
          <h3 className="text-center mb-5">Provide organisation or user email</h3>
          <MappedInput label="Email" name="email" />
          <div className="d-flex justify-content-center mt-10">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="medium"
                onClick={handleClose}
                disabled={isSubmitting || loading}
                data-testid="cancel"
                variant="outlined"
                boxShadow={false}
                text="#407A28"
              />
            </div>
            <div className="mr-3">
              <ListButton
                label="Share Certificate"
                size="medium"
                onClick={handleSubmit}
                boxShadow={false}
                disabled={isSubmitting || loading}
                data-testid="submit"
              />
            </div>
            <div>
              <ListButton
                label="Share Invoice"
                size="medium"
                onClick={() => handleShareInvoice(values, setSubmitting)}
                boxShadow={false}
                disabled={isSubmitting || loading}
              />
            </div>
          </div>
          <div className="py-2">{(isSubmitting || loading) && <LinearProgress />}</div>
        </div>
      )}
    </Formik>
  );
};
