export const HEADINGS = [
  ["code", "Batch Code"],
  ["product", "Product"],
  ["sample_reference", "Customer Ref"],
  ["test_code", "Test Code"],
  ["bill_to", "Bill to"],
  ["test_organisation", "Test Org"],
  ["site", "Test Site"],
  ["state", "State"],
  ["submit_date", "Submitted"],
  ["receive_date", "Received"],
  ["result_date", "Results"],
  ["status", "Status"],
];

export const SEARCH_HELPER = [
  ["code", "Batch Code"],
  ["biller_org.trading_name", "Bill To"],
  ["tester_org.trading_name", "Test Org"],
  ["test_code", "Test Code"],
  ["product", "Product"],
  ["sample_reference", "Customer Ref"],
  ["status", "Status"],
];

export const searchMap = {
  "Batch Code": "code",
  "Customer Ref": "sample_reference",
  "Bill To": "biller_org.trading_name",
  "Test Org": "tester_org.trading_name",
  "Test Code": "test_code",
  Product: "product",
  Status: "status",
};

export const filterDataHelper = {
  "Bill To": "biller_org_id",
  "Test Org": "tester_org_id",
  "Test Code": "test_id",
  Product: "crop_id",
  "Batch Code": "code",
  "Customer Ref": "sample_reference",
  Status: "status",
};

export const defaultStatusOptions = [
  {
    value: "submitted",
    label: "Submitted",
  },
  {
    value: "received",
    label: "Received",
  },
  {
    value: "pending_results",
    label: "Pending Results",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

const submittedStatusOptions = [
  {
    value: "submitted",
    label: "Submitted",
  },
  {
    value: "received",
    label: "Received",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

const receivedStatusOptions = [
  {
    value: "received",
    label: "Received",
  },
  {
    value: "pending_results",
    label: "Pending Results",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

const pendingStatusOptions = [
  {
    value: "pending_results",
    label: "Pending Results",
  },
  {
    value: "plat_review",
    label: "Platform Review",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

const platStatusOptions = [
  {
    value: "plat_review",
    label: "Platform Review",
  },
  {
    value: "admin_review",
    label: "Admin Review",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

const adminStatusOptions = [
  {
    value: "admin_review",
    label: "Admin Review",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

const completedStatusOptions = [
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

const cancelledStatusOptions = [
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

export const statusOptionsMap = {
  Submitted: submittedStatusOptions,
  Received: receivedStatusOptions,
  "Pending Results": pendingStatusOptions,
  "Plat Review": platStatusOptions,
  "Admin Review": adminStatusOptions,
  Completed: completedStatusOptions,
  Cancelled: cancelledStatusOptions,
};
