import config from "react-global-configuration";

export const addTest = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/tests`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getTests = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tests?${search && "&" + search}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTestById = (test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyTest = (data, test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteTest = (test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getTestAnalytes = (test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}/analytes`, {
    mode: "cors",
    credentials: "include",
  });

export const linkAnalytes = (data, test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}/analytes`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const unlinkAnalytes = (data, test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}/analytes`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getTestLaboratories = (test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}/laboratories`, {
    mode: "cors",
    credentials: "include",
  });

export const linkLaboratories = (data, test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}/laboratories`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const unlinkLaboratories = (data, test_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tests/${test_id}/laboratories`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getTestsAutocomplete = (
  page_size = 20,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tests?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
