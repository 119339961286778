import React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { initialValues, testCategoryOptions } from "./constants";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addTest, modifyTest } from "./_api";
import { MappedSwitch } from "../../../../ui/components/MappedSwitch";

export const TestDetails = ({ data = {}, id, name }) => {
  const { request } = useFetch();
  const history = useHistory();

  const handleSubmit = (values, { setSubmitting }) => {
    request(id ? modifyTest : addTest, values, id)
      .then((data) => data && history.push("/settings/tests"))
      .finally(() => setSubmitting(false));
  };

  const handleClose = () => history.goBack();

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik onSubmit={handleSubmit} initialValues={id ? data : initialValues}>
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? name : "Add Test"}</strong>
            </h3>
            <div className="row">
              <div className="col-8">
                <MappedInput label="Test Name" name="name" />
                <MappedInput label="Test Code" name="code" />
                <MappedSelect label="Test Category" name="category" options={testCategoryOptions} />
                <div className="d-flex align-items-center mt-3">
                  <p className="text-muted mt-2 mr-2">Favourite</p>
                  <MappedSwitch name="favourite" checked={values.favourite} />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
