import { sortHelper } from "../../../ui/helpers";

export const HEADINGS = [
  ["code", "Org ID"],
  ["trading_name", "Business Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["status", "Status"],
  ["abn_verified", "ABN Status"],
];

export const searchMap = {
  "Org ID": "code",
  "Business Name": "trading_name",
  "Phone Number": "phone",
  "Email Address": "email",
  Tag: "tag",
  Status: "status",
  "ABN Status": "abn_verified",
};

export const SEARCH_HELPER = [
  ["code", "Org ID"],
  ["trading_name", "Business Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["tag", "Tag"],
  ["status", "Status"],
  ["abn_verified", "ABN Status"],
];

export const statusOptions = [
  { value: "new", label: "New" },
  { value: "active", label: "Active" },
];

export const stateOptions = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "WA", label: "WA" },
  { value: "VIC", label: "VIC" },
].sort(sortHelper);
