export const HEADINGS = [
    ["code", "Batch Code"],
    ["product", "Product"],
    ["sample_reference", "Customer Ref"],
    ["test_code", "Test Code"],
    ["bill_to", "Bill to"],
    ["test_organisation", "Test Org"],
    ["site", "Test Site"],
    ["state", "State"],
    ["submit_date", "Submitted"],
    ["receive_date", "Received"],
    ["status", "Status"],
    ["special_request", "Note"]
  ];
  