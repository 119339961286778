const type = "invoice-reconciliation";

export const setData = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      testsList: payload,
    };
  },
  type,
});