import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import Button from "@bit/the-glue.frontendcomponents.button";
import { Auth } from "aws-amplify";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { UserPasswordSchema } from "../../../helpers/schemas";
import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons/";
import { error } from "../../../helpers/toasts";
import { Modal } from "../../../ui/components/Modal";
import { ResetPassword } from "./ResetPassword";
import { ConfirmPassword } from "./ConfirmPassword";
import { info } from "../../../helpers/toasts";

export const PasswordInfo = () => {
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState("reset");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const oneUpperCase = /^.*[A-Z]$/;
  const oneNumber = /^.*[0-9].*$/;
  const oneSpecialCharacter = /^[a-zA-Z0-9!@#$%^&*)(+=._-]*$/;

  const username = useSelector(
    ({
      auth: {
        user: { username },
      },
    }) => username
  );

  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          values.old_password,
          values.new_password
        );
      })
      .then((data) => {
        if (data) {
          info("User information has been updated!")
          resetForm();
        }
      })
      .catch((err) => error(err.message))
      .finally(() => setSubmitting(false));
  };

  const steps = {
    reset: (
      <ResetPassword
        handleClose={handleCloseModal}
        setStep={setStep}
        username={username}
      />
    ),
    confirm: (
      <ConfirmPassword
        handleClose={handleCloseModal}
        setStep={setStep}
        username={username}
      />
    ),
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={UserPasswordSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <Modal isOpen={openModal} modalContent={steps[step]} submitable maxWidth="sm" />
          <div className="bg-white p-8 rounded">
            <div className="row">
              <div className="col col-lg-12">
                <form>
                  <h3 className="mb-10">
                    <strong>Password</strong>
                  </h3>
                  <div className="row mb-5">
                    <div className="col-6">
                      <span
                        style={{
                          fontSize: "1.1rem",
                          padding: "16px",
                          display: "block",
                        }}
                      >
                        New Password must contains:
                      </span>
                      <div className="pl-12">
                        {values.new_password.length < 14 ? (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        ) : (
                          <CheckCircleIcon style={{ color: "#2196F3" }} />
                        )}{" "}
                        At least 14 characters
                      </div>
                      <div className="pl-12">
                        {!oneUpperCase.exec(values.new_password) ? (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        ) : (
                          <CheckCircleIcon style={{ color: "#2196F3" }} />
                        )}{" "}
                        At least 1 uppercase
                      </div>
                      <div className="pl-12">
                        {!oneNumber.exec(values.new_password) ? (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        ) : (
                          <CheckCircleIcon style={{ color: "#2196F3" }} />
                        )}{" "}
                        At least 1 number
                      </div>
                      <div className="pl-12">
                        {oneSpecialCharacter.exec(values.new_password) ? (
                          <CheckIcon style={{ color: "rgba(0,0,0,.2)" }} />
                        ) : (
                          <CheckCircleIcon style={{ color: "#2196F3" }} />
                        )}{" "}
                        At Least 1 special character
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <MappedInput
                          name="old_password"
                          label="Previous Password"
                          type="password"
                        />
                      </div>
                      <div style={{ margin: "24px 0 24px 0" }}>
                        <MappedInput name="new_password" label="New Password" type="password" />
                      </div>
                      <div>
                        <MappedInput
                          name="confirm_password"
                          label="Confirm New Password"
                          type="password"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-5">
                    <div
                      className="text-uppercase text-muted cursor-pointer"
                      onClick={handleOpenModal}
                    >
                      Restore Password
                    </div>
                    <Button
                      label="Submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      background="#407A28"
                      text="#E8F5E1"
                      textTransform="uppercase"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
