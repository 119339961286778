import config from "react-global-configuration";
import { modifyTestFieldSearch } from "../helpers";

export const addTestRequest = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getTestRequests = (
  statusList = [],
  page = 0,
  page_size = 10,
  search = "",
  field = "",
  direction = "",
  overdue
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/test_requests${overdue ? "/overdue" : ""}?${
      statusList.length ? "count_only=y" + statusList.map((status) => "&status=" + status) : ""
    }${(field && "&order_by=" + modifyTestFieldSearch(field)) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOverdueTestRequests = () =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/overdue`, {
    mode: "cors",
    credentials: "include",
  });

export const getTenantOverdueTestRequests = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/test_requests/overdue`, {
    mode: "cors",
    credentials: "include",
  });

export const getTestRequestById = (test_request_id) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/${test_request_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyTestRequest = (data, test_request_id) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/${test_request_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getTenantTestRequests = (
  tenant_id,
  statusList = [],
  page = 0,
  page_size = 10,
  search = "",
  field = "",
  direction = "",
  overdue = ""
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/test_requests${overdue ? "/overdue" : ""}?${
      statusList.length ? "count_only=y" + statusList.map((status) => "&status=" + status) : ""
    }${(field && "&order_by=" + modifyTestFieldSearch(field)) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const exportCertificate = (filename, props = []) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/${props[0]}/certificate `, {
    mode: "cors",
    credentials: "include",
  });

export const exportMATACertificate = (filename, props = []) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/${props[0]}/nata-certificate `, {
    mode: "cors",
    credentials: "include",
  });

export const generateLabels = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/labels`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const generateTenantLabels = (data, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/test_requests/labels`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const changeTestStatusMultiple = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/change-status`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const changeTenantTestStatusMultiple = (data, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/test_requests/change-status`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getNationalTestVolumes = () =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/reports?report_type=test_volume`, {
    mode: "cors",
    credentials: "include",
  });

export const getStateTestVolumes = (tenant_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/test_requests/reports?report_type=test_volume`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
export const getNationalDailyTestVolumes = () =>
  fetch(`${config.get("ApiUrl").Rest}/test_requests/reports?report_type=daily_volume`, {
    mode: "cors",
    credentials: "include",
  });

export const getStateDailyTestVolumes = (tenant_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/test_requests/reports?report_type=daily_volume`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTestRequestsAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search,
  overdue,
  tenant_id
) =>
  fetch(
    `${config.get("ApiUrl").Rest}${tenant_id ? `/tenants/${tenant_id}` : ""}/test_requests${
      overdue ? "/overdue" : ""
    }?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTestsAmount = (tenant_id, search = "", overdue) => {
  return fetch(
    `${config.get("ApiUrl").Rest}${tenant_id ? `/tenants/${tenant_id}` : ""}/test_requests${
      overdue ? "/overdue" : ""
    }?count_only=y${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};
