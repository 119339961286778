import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";
import { uploadFile } from "../../app/modules/AWS-S3/upload";
import { error } from "../../helpers/toasts";

const uploadFileStatus = {
  uploaded: "uploaded",
  failed: "failed",
};

function getIcon(fileStatus) {
  const { uploaded, failed } = uploadFileStatus;

  switch (fileStatus) {
    case uploaded:
      return <CheckCircleIcon className="text-success" fontSize="large" />;
    case failed:
      return <ErrorOutlineIcon className="text-danger" fontSize="large" />;
    default:
      return <CircularProgress className="text-warning" size="2rem" />;
  }
}

export default function File({ file, senderId, senderType, updateFiles, saveUrlFieldsValues }) {
  const [fileStatus, setFileStatus] = useState();

  useEffect(() => {
    uploadFile(senderType, senderId, file, saveUrlFieldsValues)
      .then(() => setFileStatus(uploadFileStatus.uploaded))
      .then(() => updateFiles())
      .catch((err) => {
        setFileStatus(uploadFileStatus.failed);
        error((err || {}).message);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <li className="d-flex justify-content-center align-content-center pb-3">
      <div>
        {file.name}&nbsp;{getIcon(fileStatus)}
      </div>
    </li>
  );
}
