import React from "react";
import { ReactComponent as DashboardIcon } from "../../../../../ui/static/Dashboard.svg";
import { ReactComponent as ResultsIcon } from "../../../../../ui/static/Results.svg";
import { ReactComponent as SettingsIcon } from "../../../../../ui/static/Settings.svg";
import { ReactComponent as IntegrationsIcon } from "../../../../../ui/static/Integrations.svg";
import { ReactComponent as UsersIcon } from "../../../../../ui/static/Users.svg";

export const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu",
};

export const NATIONAL_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.link,
    title: "Integrations",
    icon: <IntegrationsIcon />,
    to: "/integrations",
  },
  {
    type: ITEM_TYPES.link,
    title: "Users",
    icon: <UsersIcon />,
    to: "/users-list",
  },
  {
    type: ITEM_TYPES.link,
    title: "Tenant Administration",
    icon: <SettingsIcon />,
    to: "/tenant-administration",
  },
];

const platformResults = {
  type: ITEM_TYPES.menu,
  title: "Results",
  icon: <ResultsIcon />,
  to: "/results",
  items: [
    {
      type: "link",
      title: "Results List",
      to: "/results-list",
    },
    {
      type: "link",
      title: "Results Review",
      to: "/results-review",
    },
    {
      type: "link",
      title: "Results Review (Platform)",
      to: "/results-platform",
    },
  ],
};

export const PLATFORM_MENU = [
  ...NATIONAL_MENU.slice(0, 3),
  platformResults,
  ...NATIONAL_MENU.slice(4),
];

export const STATE_MENU = [
  {
    type: ITEM_TYPES.link,
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.link,
    title: "Integrations",
    icon: <IntegrationsIcon />,
    to: "/integrations",
  },
];

export const END_USER_MENU = [];

export const GUEST_MENU = [];
