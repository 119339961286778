export const HEADINGS = [
  ["code", "Test Code"],
  ["name", "Test Name"],
  ["category", "Test Category"],
  ["status", "Status"],
];

export const searchMap = {
  "Test Name": "name",
  "Test Code": "code",
  "Test Category": "category",
};

export const initialValues = {
  name: "",
  code: "",
  favourite: false,
};

export const analytesHeadings = [
  ["code", "Analyte ID"],
  ["name", "Analyte Name"],
  ["level_of_reporting", "Limit of Reporting"],
  ["status", "Status"],
];

export const laboratoryHeadings = [
  ["code", "Org ID"],
  ["trading_name", "Organisation Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["status", "Status"],
];

export const testCategoryOptions = [
  { value: "Microbial", label: "Microbial" },
  { value: "Water", label: "Water" },
  { value: "Chemical", label: "Chemical" },
  { value: "Heavy Metals", label: "Heavy Metals" },
  { value: "Soil", label: "Soil" },
  { value: "Other", label: "Other" },
];
