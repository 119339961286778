/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedMaskedInput } from "@bit/the-glue.frontendcomponents.masked-input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { OrganisationDetailsSchema } from "../../../helpers/schemas";
import { stateOptions, statusOptions } from "./constants";
import { TagsField } from "../../../ui/structures/../structures/TagsForm/TagsField";
import { Modal } from "../../../ui/components/Modal";
import { TagsAutocomplete } from "../../../ui/structures/TagsForm/TagsAutocomplete";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  addOrganisation,
  getSimpleOrganisations,
  modifyOrganisation,
  abnLookup,
  editOrganisationTags,
  deleteOrganisationTags,
  getOrganisations,
} from "./_api";
import { getTags } from "../Settings/Tags/_api";
import { PostalAddress } from "./PostalAddress";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";
import { Loader } from "../../../ui/components/Loader";
import { ListButton } from "../../../ui/components/ListButton";
import { getPriceLists } from "../Settings/Prices/_api";
import { TradingNameSelect } from "./TradingNameSelect";
import { uniq } from "lodash";
import { AbnOfflineWarning } from "./AbnOfflineWarning";
import { info } from "../../../helpers/toasts";
import { MergeOrganisationForm } from "./MergeOrganisationForm";
import { sortHelper } from "../../../ui/helpers";
import { modifyOrganisationData } from "./helpers";

export const OrganisationDetails = ({
  data = {},
  id,
  name,
  simpleOrganisations = [],
  setCardData,
}) => {
  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const history = useHistory();
  const { request } = useFetch();

  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [priceLists, setPriceLists] = useState([{ value: "", label: "Select Price List" }]);
  const [abnLookedUp, setAbnLookedUp] = useState(id ? true : false);
  const [organisationsList, setOrganisationsList] = useState(simpleOrganisations);
  const [tradingNameSelect, setTradingNameSelect] = useState(false);
  const [tradingNamesList, setTradingNamesList] = useState([]);
  const [tradingNameValue, setTradingNameValue] = useState("");
  const [tradingNameisInput, setTradingNameisInput] = useState(true);
  const [abnWarningOpen, setAbnWarningOpen] = useState(false);
  const [abnLoading, setAbnloading] = useState(false);
  const [tradingNameLoading, setTradingNameLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);

  const handleMergeModalOpen = () => setMergeModalOpen(true);
  const handleMergeModalClose = () => setMergeModalOpen(false);

  const handleNameSelectClose = () => setTradingNameSelect(false);
  const handleAbnWarningClose = () => setAbnWarningOpen(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const sortTagsHelper = (a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  };

  useEffect(() => {
    setTagsLoading(true);
    request(getTags, "type=_like(Organisation)_")
      .then((tags) => tags && setTags(tags.sort(sortTagsHelper)))
      .finally(() => setTagsLoading(false));
    request(getPriceLists).then(
      (prices) =>
        prices &&
        setPriceLists((state) => [
          ...state,
          ...prices.map(({ name, id }) => ({ value: id, label: name })),
        ])
    );
  }, []);

  useEffect(() => {
    if (organisationsList.length) return;
    request(getSimpleOrganisations).then((data) => data && setOrganisationsList(data));
  }, []);

  useEffect(() => {
    if (data.ABN) {
      setTradingNameLoading(true);
      setTradingNameisInput(false);
      request(abnLookup, data.ABN)
        .then((data) => {
          if (!data) return;
          const filteredNames = uniq(data.trading_names);
          const tradingNamesOptions = [
            ...filteredNames.map((name) => ({
              value: name,
              label: name,
            })),
            { value: data.legal_name, label: data.legal_name },
          ];
          setTradingNamesList(tradingNamesOptions);
        })
        .finally(() => setTradingNameLoading(false));
    }
  }, []);

  const initialValues = {
    ABN: data.ABN || "",
    legal_name: data.legal_name || "",
    phone: data.phone || "",
    mobile: data.mobile || "",
    email: data.email || "",
    trading_name: data.trading_name || "",
    price_list_id: data.price_list_id || "",
    status: data.status || "new",
    notes: data.notes || "",
    tags: id ? (data.tags || []).map(({ id }) => id) : [],
    physical_address: data.physical_address || "",
    physical_address2: data.physical_address2 || "",
    physical_city: data.physical_city || "",
    physical_state: data.physical_state || "",
    physical_postcode: data.physical_postcode || "",
    postal_address: data.postal_address || "",
    postal_address2: data.postal_address2 || "",
    postal_city: data.postal_city || "",
    postal_state: data.postal_state || "",
    postal_postcode: data.postal_postcode || "",
    code: data.code || "",
    as_physical: false,
  };

  const createOrganisation = (values, setSubmitting) => {
    setLoading(true);
    const organisationPayload = { ...values };
    delete organisationPayload.tags;
    request(addOrganisation, organisationPayload)
      .then((data) => {
        if (!data) return;
        if (values.tags.length) {
          return request(
            editOrganisationTags,
            data.id,
            values.tags.map((tagID) => ({ id: tagID }))
          ).finally(() => {
            setSubmitting(false);
            setLoading(false);
          });
        } else return data;
      })
      .then((data) => data && history.push("/organisations/organisations-list"));
  };

  const editTagsRequest = (values) => {
    if (values.tags.length) {
      return request(
        editOrganisationTags,
        id,
        values.tags.map((tagID) => ({ id: tagID }))
      );
    } else if (!values.tags.length && data.tags.length) {
      return request(deleteOrganisationTags, id, data.tags);
    } else return "success";
  };

  const updateOrganisation = (values, setSubmitting) => {
    setLoading(true);
    const organisationPayload = { ...values };
    delete organisationPayload.tags;
    Promise.all([request(modifyOrganisation, organisationPayload, id), editTagsRequest(values)])
      .then(([organisationData, tags]) => {
        if (!organisationData || !tags) return;
        const modifiedData = modifyOrganisationData(organisationData);
        setCardData((state) => ({ ...state, ...modifiedData }));
        info("Details have been updated!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const tenantAdminPayload = { ...values, tenant_id: admins_tenant };
    id
      ? updateOrganisation(admins_tenant ? tenantAdminPayload : values, setSubmitting)
      : createOrganisation(admins_tenant ? tenantAdminPayload : values, setSubmitting);
  };

  const handleDuplicate = (abn = "") => {
    return request(getOrganisations, "", `ABN=_like(${abn})_`);
  };

  const handleBlur = (values, setTouched, setFieldValue) => () => {
    if (values.ABN.includes("_")) return;
    setAbnloading(true);
    handleDuplicate(values.ABN).then((data) => {
      if ((data || []).length && ((data || [])[0] || {}).id !== id) {
        setAbnLookedUp(false);
        setAbnloading(false);
        info("Organisation already exists");
      } else if ((data || []).length === 0 || ((data || [])[0] || {}).id === id) {
        request(abnLookup, values.ABN)
          .then((data) => {
            if (!data) {
              setAbnLookedUp(false);
              setAbnloading(false);
            } else if (data === "Server error") {
              setTradingNameisInput(true);
              setAbnWarningOpen(true);
              setAbnLookedUp(true);
              setAbnloading(false);
            } else {
              setTradingNameisInput(false);
              setAbnLookedUp(true);
              setAbnloading(false);
              Object.keys(data).map((key) => setFieldValue(key, data[key]));
              const filteredNames = uniq(data.trading_names);
              const tradingNamesOptions = [
                ...filteredNames.map((name) => ({
                  value: name,
                  label: name,
                })),
                { value: data.legal_name, label: data.legal_name },
              ];
              setTradingNamesList(tradingNamesOptions);
              if (tradingNamesOptions.length === 1) {
                setFieldValue("trading_name", tradingNamesOptions[0].value);
              } else {
                setFieldValue("trading_name", "");
                setTradingNameSelect(true);
              }
            }
          })
          .finally(() => setAbnloading(false));
      } else {
        setAbnLookedUp(false);
      }
    });
    setTouched({ ABN: true }, true);
  };

  const hanldeSelectTradingName = (setFieldValue) => () => {
    setFieldValue("trading_name", (tradingNameValue || {}).value);
    setTradingNameSelect(false);
  };

  const handleClose = () => history.goBack();

  const organiastionOptions = useMemo(
    () =>
      organisationsList
        .map((organiastion) => ({
          value: organiastion.id,
          label: organiastion.trading_name,
        }))
        .sort(sortHelper),
    [organisationsList]
  );

  return (
    <>
      {(abnLoading || loading) && (
        <Loader isOpen={abnLoading || loading} maxWidth="xs" title="Loading..." />
      )}
      {abnWarningOpen && (
        <Modal
          maxWidth="sm"
          isOpen={abnWarningOpen}
          submitable
          onClose={handleAbnWarningClose}
          modalContent={<AbnOfflineWarning onClose={handleAbnWarningClose} id={id} />}
        />
      )}
      {mergeModalOpen && (
        <Modal
          maxWidth="md"
          isOpen={mergeModalOpen}
          submitable
          onClose={handleMergeModalClose}
          modalContent={
            <MergeOrganisationForm
              organiastionOptions={organiastionOptions}
              handleClose={handleMergeModalClose}
              organisationID={id}
            />
          }
        />
      )}
      <div className="border border-secondary bg-white p-10">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={OrganisationDetailsSchema}
        >
          {({ handleSubmit, isSubmitting, setFieldValue, setTouched, values }) => (
            <>
              {modalOpen && (
                <Modal
                  maxWidth="sm"
                  isOpen={modalOpen}
                  submitable
                  onClose={handleModalClose}
                  modalContent={
                    <TagsAutocomplete
                      name="tags"
                      placeholder="Select Tags"
                      setValue={setFieldValue}
                      options={tags.filter((item) => !values.tags.includes(item.id))}
                      loading={!tags.length}
                      onClose={handleModalClose}
                      currentValue={values.tags}
                      multiple
                    />
                  }
                />
              )}
              {tradingNameSelect && (
                <Modal
                  maxWidth="sm"
                  isOpen={tradingNameSelect}
                  submitable
                  onClose={handleNameSelectClose}
                  modalContent={
                    <TradingNameSelect
                      onClose={handleNameSelectClose}
                      handleSubmit={hanldeSelectTradingName(setFieldValue)}
                      setTradingNameValue={setTradingNameValue}
                      tradingNamesList={tradingNamesList}
                    />
                  }
                />
              )}
              <h3 className="mb-5 d-flex justify-content-between">
                <div>
                  <strong>{name || "Create Organisation"}</strong>
                </div>
                <div>{id && `ABN Is ${data.abn_verified}`}</div>
              </h3>
              <div className="row justify-content-between">
                <div className="col-6">
                  <MappedMaskedInput
                    label="ABN"
                    name="ABN"
                    data-testid="abn"
                    mask="99 999 999 999"
                    onBlur={handleBlur(values, setTouched, setFieldValue)}
                  />
                  <MappedInput
                    label="Legal Name"
                    name="legal_name"
                    data-testid="legal_name"
                    disabled
                  />
                  <MappedInput
                    label="Phone"
                    name="phone"
                    data-testid="phone"
                    disabled={!abnLookedUp}
                  />
                  <MappedInput
                    label="Mobile"
                    name="mobile"
                    data-testid="mobile"
                    disabled={!abnLookedUp}
                  />
                  <MappedInput
                    label="Email"
                    name="email"
                    data-testid="email"
                    disabled={!abnLookedUp}
                  />
                  {tradingNameLoading ? (
                    <div className="mb-3">
                      <Skeleton variant="rect" width={"100%"} height={50} />
                    </div>
                  ) : tradingNameisInput ? (
                    <MappedInput
                      label="Business Name"
                      name="trading_name"
                      data-testid="trading_name"
                      disabled={!abnLookedUp}
                    />
                  ) : (
                    <MappedSelect
                      label="Business Name"
                      options={tradingNamesList}
                      name="trading_name"
                      data-testid="trading_name"
                      disabled={!abnLookedUp}
                    />
                  )}
                  {id && <MappedInput label="Code" name="code" data-testid="code" disabled />}
                </div>
                <div className="col-6">
                  <div className="d-flex w-100">
                    <div style={{ width: "47%", marginRight: "15px" }}>
                      <AccessControl
                        desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
                        elseContent={
                          <MappedSelect
                            label="Price List"
                            options={priceLists}
                            name="price_list_id"
                            data-testid="price_code"
                            disabled={!abnLookedUp}
                            displayEmpty
                          />
                        }
                      >
                        <MappedSelect
                          label="Price List"
                          options={priceLists}
                          name="price_list_id"
                          data-testid="price_code"
                          disabled={!abnLookedUp}
                          displayEmpty
                        />
                      </AccessControl>
                      <AccessControl
                        desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
                        elseContent={
                          <MappedSelect
                            label="Status"
                            options={statusOptions}
                            name="status"
                            data-testid="status"
                            disabled
                          />
                        }
                      >
                        <MappedSelect
                          label="Status"
                          options={statusOptions}
                          name="status"
                          data-testid="status"
                          disabled={!abnLookedUp}
                        />
                      </AccessControl>
                    </div>
                    <div className="w-50">
                      {tagsLoading ? (
                        <div className="mb-5">
                          <Skeleton variant="rect" width={"100%"} height={150} />
                        </div>
                      ) : (
                        <TagsField
                          label="Tags"
                          tags={values.tags}
                          handleAdd={handleModalOpen}
                          setValue={setFieldValue}
                          predefinedTags={tags}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <MappedInput
                      label="Notes"
                      name="notes"
                      multiline
                      rows={12}
                      data-testid="notes"
                      disabled={!abnLookedUp}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between mt-10">
                <div className="col-6">
                  <h5 className="mb-4">
                    <strong>Physical Address</strong>
                  </h5>
                  <MappedInput
                    label="Address 1"
                    name="physical_address"
                    data-testid="physical_address"
                  />
                  <MappedInput
                    label="Address 2"
                    name="physical_address2"
                    data-testid="physical_address2"
                  />
                  <MappedInput label="City" name="physical_city" data-testid="physical_city" />
                  <div className="row">
                    <div className="col-6">
                      <MappedSelect
                        label="State"
                        name="physical_state"
                        data-testid="physical_state"
                        options={stateOptions}
                      />
                    </div>
                    <div className="col-6">
                      <MappedInput
                        label="Postal Code"
                        name="physical_postcode"
                        data-testid="physical_postcode"
                      />
                    </div>
                  </div>
                </div>
                <PostalAddress id={id} />
              </div>
              <div className="mt-10 d-flex justify-content-end mr-4">
                <AccessControl desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}>
                  {data.status !== "archived" && id ? (
                    <div className="mr-3">
                      <ListButton
                        label="Merge"
                        size="large"
                        boxShadow={false}
                        onClick={handleMergeModalOpen}
                      />
                    </div>
                  ) : null}
                </AccessControl>
                <div className="mr-3">
                  <ListButton
                    label="Cancel"
                    size="large"
                    onClick={handleClose}
                    data-testid="cancel"
                    variant="outlined"
                    boxShadow={false}
                    text="#2980c4"
                  />
                </div>
                <div>
                  <ListButton
                    label="Save"
                    size="large"
                    onClick={handleSubmit}
                    boxShadow={false}
                    disabled={isSubmitting || !abnLookedUp}
                    data-testid="submit"
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};
