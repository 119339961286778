import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TableSortLabel } from "@material-ui/core";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { uniq } from "lodash";
import { getSelectedId, handleSearch, objectCheck } from "../../../ui/helpers";
import { HEADINGS, searchMap, SEARCH_HELPER } from "./constants";
import {
  removeOrganisation,
  setOrganisationsList,
  updateOrganisationsList,
} from "./_redux/actions";
import { modifyOrganisationData, modifyOrganisations, renderOrganisationsRow } from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import {
  getOrganisations,
  archiveOrganisation,
  getOrganisationById,
  modifyOrganisation,
  getTenantOrganisations,
  getOrganisationsListAutocomplete,
  getOrganisationsAmount,
} from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { getTagsListAutocomplete } from "../Settings/Tags/_api";
import { Loader } from "../../../ui/components/Loader";
import { info } from "../../../helpers/toasts";
import { AccessControl } from "../../../ui/structures/AccessControl";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [focused, setFocused] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(({ organisations: { organisationsList } }) => organisationsList);
  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;

  const requestOrganisations = () => {
    if (admins_tenant) {
      return request(
        getTenantOrganisations,
        admins_tenant,
        "",
        search,
        archived,
        field,
        field && direction,
        page,
        pageSize
      );
    } else
      return request(
        getOrganisations,
        "",
        search,
        archived,
        field,
        field && direction,
        page,
        pageSize
      );
  };

  const fetchOrganisations = () => {
    setLoading(true);
    requestOrganisations()
      .then((data) => data && modifyOrganisations(data))
      .then((data) => {
        if (!data) return;
        dispatch(setOrganisationsList(data));
      })
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  };

  const requestOptions = (autocompleteValue, autocompleteField, searchFields, archived) => {
    if (autocompleteField === "tag") {
      return request(
        getTagsListAutocomplete,
        80,
        "name",
        autocompleteValue,
        "type=_like(Organisation)_"
      );
    } else
      return request(
        getOrganisationsListAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        field,
        field && direction,
        searchFields,
        archived,
        admins_tenant
      );
  };

  const fetchOrganisationsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    if (autocompleteField === "abn_verified") {
      setAutocompleteData(["Verified", "Not Verified"]);
      return;
    }
    if (autocompleteField === "status") {
      setAutocompleteData(["new", "active"]);
      return;
    }
    setAutocompleteLoading(true);
    // SearchFields are needed to apply previous filters
    requestOptions(autocompleteValue, autocompleteField, searchFields, archived).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchOrganisations();
    // eslint-disable-next-line
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getOrganisationsAmount, admins_tenant, search, archived).then(
      (data) => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search, archived]);

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setConfirmDeleteOpen(false);
    setActionLoading(true);
    request(archiveOrganisation, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getOrganisationById, id).then(
            (data) => data && dispatch(updateOrganisationsList(modifyOrganisationData(data)))
          );
        } else dispatch(removeOrganisation(id));
        setSelected({});
        info("Organisation has been archived!");
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const organisationID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === organisationID) || {}).status;
    if (status !== "archived") return;
    setActionLoading(true);
    setUnarchiving(true);
    request(modifyOrganisation, { status: "active" }, organisationID)
      .then((data) => {
        if (!data) return;
        dispatch(updateOrganisationsList(modifyOrganisationData(data)));
        setSelected({});
        info("Organisation has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <AccessControl
      desiredRoll={AVAILABLE_ROLES.NATIONAL_ADMIN}
      elseContent={
        <div className="mr-10">
          <Link
            to={{
              pathname: `/add-organisation`,
            }}
            data-testid="new"
          >
            <ListButton label="New" />
          </Link>
        </div>
      }
    >
      <>
        {archived && (
          <UnarchiveButton
            handleClick={handleUnarchive}
            disabled={unArchiveDisabled}
            loading={unArchiving}
          />
        )}
        <div className="mr-10">
          <Link
            to={{
              pathname: `/add-organisation`,
            }}
            data-testid="new"
          >
            <ListButton label="New" />
          </Link>
        </div>
      </>
    </AccessControl>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    setSearch("");
  };

  const handleFocus = () => setFocused(true);

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <>
      {actionLoading && <Loader title="Loading..." isOpen={actionLoading} />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).legal_name}
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) => handleSearch(data, searchMap, setSearch, setPage)}
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchOrganisationsAutocomplete}
              placeholder="Search Organisations..."
              clearSearch={resetSearch}
              focused={focused}
              setFocused={setFocused}
              searchMap={searchMap}
              searchFields={search}
              archived={archived}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader
              title="Organisations"
              renderButtons={renderButtons}
              handleFocus={handleFocus}
            />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderOrganisationsRow}
              selected={selected}
              setSelected={setSelected}
              editable
              loading={loading}
              selectable
              deletable={Boolean(!admins_tenant)}
              handleDelete={handleOpenModal}
              link="organisations-list"
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
