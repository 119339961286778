import React from "react";
import LensIcon from "@material-ui/icons/Lens";
import { Link } from "react-router-dom";
import {
  formatDate,
  formatPrice,
  isDate,
  isPrice,
  isStatus,
  isDonwloadKey,
  isLink,
} from "../../../ui/helpers";
import { get } from "lodash";
import { RenderInvoiceDownload } from "./RenderInvoiceDownload";
import ToolTip from "../../../ui/components/Tooltip";

const statusMap = {
  paid: "text-success",
  unpaid: "text-warning",
};

const renderStatusField = (item) => {
  return (
    <td className={`${statusMap[item.payment_status]} no-line border-0 px-5 text-uppercase`}>
      <LensIcon fontSize="small" className="mr-2" /> {item.payment_status}
    </td>
  );
};

const formatTestCodeRow = (codes = []) => {
  if (codes.length > 3) {
    return (
      <ToolTip
        content={<div>{codes.slice(0, 3).join(", ") + " ..."}</div>}
        title={codes.join(", ")}
      />
    );
  } else return codes.join(", ");
};

function isTestCode(key) {
  return ["test_code"].includes(key);
}

export function renderInvoicesRow(headings, item) {
  return headings.map(([key]) =>
    isStatus(key) ? (
      renderStatusField(item)
    ) : (
      <>
        <td key={key} className={`align-middle no-line border-0 px-5 py-5`}>
          {isDate(key) ? (
            item[key] ? (
              formatDate(new Date(item[key]))
            ) : (
              "N/A"
            )
          ) : isPrice(key) ? (
            formatPrice(item[key])
          ) : isDonwloadKey(key) ? (
            <RenderInvoiceDownload item={item} />
          ) : isLink(key) ? (
            <Link className="text-dark" to={`/test-invoices/${item.id}`}>
              {item[key]}
            </Link>
          ) : isTestCode(key) ? (
            formatTestCodeRow(item[key])
          ) : (
            item[key]
          )}
        </td>
      </>
    )
  );
}

export function renderInvoiceLinesRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className={`align-middle no-line border-0 px-5 py-5`}>
      {isPrice(key) ? formatPrice(item[key]) : item[key]}
    </td>
  ));
}

export const modifyInvoices = (data = []) => data.map((invoice) => modifyInvoiceData(invoice));

export const modifyInvoiceData = (invoice = {}) => {
  return {
    ...invoice,
    amount: invoice.invoice_lines
      .map(({ transaction_total }) => transaction_total)
      .reduce((acc, value) => acc + value, 0),
    organisation_name: get(invoice, "organisation.trading_name", ""),
    payment_status: invoice.payment_status ? invoice.payment_status : "unpaid",
    test_code: invoice.invoice_lines
      .map(({ transaction_description_extra }) => transaction_description_extra)
      .filter(Boolean),
  };
};

export const handleSearchInvoices = (
  data = {},
  filterDataHelper,
  setSearch,
  setPage,
  simpleOrganisations = []
) => {
  const modifiedObject = {
    ...data,
    "Bill to Org": (
      simpleOrganisations.find(({ trading_name }) => trading_name === data["Bill to Org"]) || {}
    ).id,
  };
  setSearch(
    Object.keys(modifiedObject)
      .map((key) => {
        if (filterDataHelper[key] && modifiedObject[key]) {
          return `${filterDataHelper[key]}=_like(${encodeURIComponent(modifiedObject[key])})_`;
        } else return "";
      })
      .join("&")
  );
  setPage && setPage(0);
};

const fieldNamesMap = {
  organisation_name: "organisation.trading_name",
};

export const modifyInvoiceFieldSearch = (field) => {
  const modifiedFields = Object.keys(fieldNamesMap);
  if (modifiedFields.includes(field)) {
    return fieldNamesMap[field];
  } else return field;
};
