import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { formatPrice } from "../../../ui/helpers";
import { getOrganisationPaymentMethods } from "../Organisations/_api";

export const Payment = ({ handleSubmit, handleStripeSubmit }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);

  const { request } = useFetch();

  const data = useSelector(({ newTest: { data } }) => data);

  const { tests, selectedTenant, customer } = data;
  const total = (tests || {}).payment_total;
  const tenant_id = (selectedTenant || {}).tenant_id;
  const biller_org_id = (customer || {}).biller_org_id;

  useEffect(() => {
    setLoading(true);
    request(getOrganisationPaymentMethods, biller_org_id)
      .then((data) => data && setPaymentMethods(data))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedPaymentMethod = paymentMethods.find((method) => (method || {}).tenant_id === tenant_id);

  const paymentMethodAvailable = (selectedPaymentMethod || {}).payment_method === "stripe";

  if (loading) return <Skeleton variant="rect" width={"100%"} height={520} />;

  return (
    <>
      <div className="bg-white p-10">
        <h4 className="mb-10">
          <strong>Choose your payment method</strong>
        </h4>
        <h5 className="mb-10">{`Payment Total : ${formatPrice(total)}`}</h5>
        <div className="d-flex">
          <div className="mr-5">
            <ListButton label="Pay by Credit Card" size="large" onClick={handleStripeSubmit} />
          </div>
          {!paymentMethodAvailable && (
            <div>
              <ListButton label=" Charge to Credit Account" size="large" onClick={handleSubmit} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
