/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { get } from "lodash";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { renderResultsRow } from "../Tests/helpers";
import { modifyResultsArray, setID } from "./helpers";
import { HEADINGS } from "./constants";
import { removeResult, setResultsList } from "./_redux/actions";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { getResults, getResultsAmount } from "./_api";
import { AnalytesList } from "./AnalytesList";
import { ListButton } from "../../../ui/components/ListButton";
import { getAnalytes } from "../Settings/Analytes/_api";
import { changeTestStatusMultiple } from "../Tests/_api";
import { Loader } from "../../../ui/components/Loader";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user) || {};
  const admins_tenant = user.admins_tenant;
  const tenants = user.tenants || [];

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [analytesData, setAnalytesData] = useState([]);
  const [selectedResult, setSelectedResult] = useState("");
  const [analytesList, setAnalytesList] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [firstExpand, setFirstExpand] = useState(false);

  const data = useSelector(({ resultsReview: { resultsList } }) => resultsList);

  const fetchResults = () => {
    setLoading(true);
    request(getResults, page, pageSize)
      .then((data) => {
        if (!data) return;
        dispatch(setResultsList(modifyResultsArray(data, tenants)));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchResults();
  }, [page, pageSize]);

  useEffect(() => {
    request(getResultsAmount, admins_tenant).then((data) => data && setTotalRecords(data));
  }, []);

  useEffect(() => {
    request(getAnalytes).then((data) => data && setAnalytesList(data));
  }, []);

  const approveDisabled = () => {
    return Object.keys(selected).filter((key) => selected[key]);
  };

  const renderButtons = () => {
    return (
      <div>
        <ListButton
          label="Approve"
          disabled={(approveDisabled() || []).length === 0}
          onClick={handleApprove}
        />
      </div>
    );
  };

  const handleExpand = (id) => () => {
    setFirstExpand(true);
    const testRequest = data.find((result) => (result || {}).id === id) || {};
    setSelectedResult(id);
    const results = testRequest.results || {};
    if (!results.length) return;
    const usedNumbers = [];
    const modifiedResults = results.map((result) => {
      const analyte = analytesList.find(
        (analyte) => (analyte || {}).code === (result || {}).AnalyteID
      );
      const itemID =  setID(10000000, usedNumbers);
      usedNumbers.push(itemID);
      return {
        ...result,
        name: (analyte || {}).name,
        type: (analyte || {}).type,
        id: itemID,
      };
    });
    modifiedResults.some(({ AnalyteID }) => AnalyteID === "AN0000760")
      ? setAnalytesData([])
      : setAnalytesData(modifiedResults);
  };

  const handleApprove = () => {
    const testIDs = approveDisabled() || [];
    const editStatusPayload = {
      test_request_ids: testIDs,
      status: "completed",
      dispatch_date: new Date(Date.now()),
    };
    if (testIDs.length) {
      setApproveLoading(true);
      request(changeTestStatusMultiple, editStatusPayload, admins_tenant)
        .then((res) => {
          if (!res) return;
          setSelected({});
          setAnalytesData([]);
          testIDs.map((testID) => dispatch(removeResult(testID)));
        })
        .finally(() => setApproveLoading(false));
    }
  };

  const getSelectedDetails = () => {
    const testRequest = data.find((result) => (result || {}).id === selectedResult) || {};
    if (Object.keys(testRequest).length) {
      return `Batch: ${testRequest.code}, Product: ${get(testRequest, "crop.name", "")}`;
    } else return null;
  };

  return (
    <>
      {approveLoading && <Loader title="Loading..." isOpen={approveLoading} />}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title={getSelectedDetails()} renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderResultsRow}
              selectable
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              expandable
              handleExpand={handleExpand}
              expandHeader="Results"
              expandIcon={<KeyboardArrowDownIcon />}
              headerClassName="nospaces-header"
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            {firstExpand ? (
              analytesData.length ? (
                <AnalytesList
                  data={analytesData}
                  setAnalytesData={setAnalytesData}
                  selectedResult={selectedResult}
                  tenants={tenants}
                />
              ) : (
                <h3 className="text-center mt-5">
                  <CheckCircleIcon
                    fontSize="large"
                    style={{ color: "#2196F3", margin: "-3px 10px 0 0" }}
                  />
                  All analytes less than LOR.
                </h3>
              )
            ) : (
              <h3 className="text-center mt-5">Please open a test request to see the results</h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
