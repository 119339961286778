import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TestsList } from "./TestsList";
import { Modal } from "../../../../ui/components/Modal";

export const CommonTests = ({
  commonTests = [],
  unCommonTests = [],
  handlePrevStep,
  handleNextStep,
  setLookaupData,
}) => {
  const [selected, setSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const selectedTests = useSelector(
    ({
      newTest: {
        data: {
          tests: { common_tests },
        },
      },
    }) => common_tests
  );

  useEffect(() => {
    const selectedObjects = selectedTests
      .map((id) => ({ [id]: true }))
      .reduce((acc, value) => ({ ...acc, ...value }), {});
    setSelected(selectedObjects);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    const selectedTests = Object.keys(selected).filter((item) => Boolean(selected[item]));
    const total = (commonTests.filter(({ id }) => (selectedTests || []).includes(id)) || []).map(
      (test) => (test || {}).price
    );
    handleNextStep(
      {
        common_tests: selectedTests,
        payment_total: (total || []).reduce((acc, value) => acc + value, 0),
      },
      "tests"
    );
  };

  const handleAddTests = () => {
    handleModalClose();
  };

  const selectedCount = Object.values(selected).filter(Boolean).length;
  return (
    <div>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={
            <TestsList
              data={unCommonTests}
              commonTests={commonTests}
              selectedCount={selectedCount}
              selected={selected}
              setSelected={setSelected}
              handleSubmit={handleAddTests}
              handlePrevStep={handleModalClose}
              setLookaupData={setLookaupData}
            />
          }
        />
      )}
      <TestsList
        data={commonTests}
        selectedCount={selectedCount}
        selected={selected}
        setSelected={setSelected}
        handleSubmit={handleSubmit}
        handlePrevStep={handlePrevStep}
        handleModalOpen={handleModalOpen}
        isFavorite
        setLookaupData={setLookaupData}
        hideSelect
      />
    </div>
  );
};
