import React, { useState } from "react";
import { Formik } from "formik";
import { Filter } from "../../../ui/components/Filter";
import {
  blockMinHeight,
  buttonClassName,
  nexStepButtonStyle,
  productFiltersMap,
} from "./constants";
import { filterResults, getKeys } from "./helpers";
import { MappedRadio } from "../../../ui/components/Radio";
import { productLookupSchema } from "../../../helpers/schemas";

export const ProductLookup = ({ data = [], handleNextStep, handlePrevStep, product }) => {
  const { crop_id, name } = product;

  const inititalValues = {
    crop_id,
    name,
  };

  const getSelectedResult = () => {
    const result = data.find(({ id }) => id === crop_id);
    return result && [result];
  };

  const getInitialFilterValue = () => {
    const selectedOption = data.find(({ id }) => crop_id === id);
    if (selectedOption) {
      return [{ name: selectedOption.name }];
    }
  };

  const [searchResults, setSearchResults] = useState(getSelectedResult() || []);
  const [filterValue, setFilterValue] = useState(getInitialFilterValue() || []);

  const handleFilter = (filterValue) => {
    const keys = getKeys(filterValue);

    setSearchResults(!keys.length ? [] : filterResults(data, keys, filterValue));
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "product");
  };

  return (
    <Formik
      initialValues={inititalValues}
      onSubmit={handleSubmit}
      validationSchema={productLookupSchema}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          <div className="row">
            <div className="col-6">
              <div className="bg-white p-10" style={blockMinHeight}>
                <h4 className="mb-10">
                  <strong>Select Product</strong>
                </h4>
                {productFiltersMap.map(({ name, placeholder }) => (
                  <Filter
                    key={name}
                    name={name}
                    placeholder={placeholder}
                    options={data}
                    handleFilter={handleFilter}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    setValue={setFieldValue}
                    defaultValue={values.crop_id}
                    radioButtonName="crop_id"
                  />
                ))}
              </div>
            </div>
            <div className="col-6">
              <div className="bg-white p-10" style={blockMinHeight}>
                <h4 className="mb-10">
                  <strong>Search Results</strong>
                </h4>
                <div style={{ minHeight: "50px" }}>
                  <MappedRadio options={searchResults} name="crop_id" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-15">
            <div className="col-6 text-center">
              <button className={buttonClassName} onClick={handlePrevStep}>
                Back
              </button>
            </div>
            <div className="col-6 text-center">
              <button className={nexStepButtonStyle} onClick={handleSubmit} type="submit">
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
