export const mockedProducts = [
  {
    name: "Test Product # 1",
    id: "1",
  },
  {
    name: "Test Product # 2",
    id: "2",
  },
];

export const blockMinHeight = {
  minHeight: "540px",
};

export const nexStepButtonStyle =
  "col-10 py-3 text-uppercase text-white border border-primary bg-primary rounded";

export const buttonClassName =
  "col-10 py-3 text-uppercase text-primary bg-white border border-primary rounded";

export const customerFiltersMap = [
  {
    name: "ABN",
    placeholder: "ABN",
  },
  {
    name: "legal_name",
    placeholder: "Legal Name",
  },
  {
    name: "trading_name",
    placeholder: "Business Name",
  },
];

export const testOrgrFiltersMap = [
  {
    name: "ABN",
    placeholder: "ABN",
  },
  {
    name: "legal_name",
    placeholder: "Legal Name",
  },
  {
    name: "trading_name",
    placeholder: "Business Name",
  },
];

export const siteFiltersMap = [
  {
    name: "name",
    placeholder: "Site Name",
  },
  {
    name: "physical_address",
    placeholder: "Address",
  },
];

export const productFiltersMap = [
  {
    name: "name",
    placeholder: "Search Product Name",
  },
];

export const tenantFiltersMap = [
  {
    name: "name",
    placeholder: "Deliver Sample to",
  },
];
