import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { modifyTestObject, modifyTestsArray, renderTestsRow } from "../../modules/Tests/helpers";
import { HEADINGS } from "../../modules/Tests/constants";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { Modal } from "../../../ui/components/Modal";
import { useFetch } from "../../../hooks/fetch.hook";
import { modifyTestRequest } from "../../modules/Tests/_api";
import { EditStatus } from "../../modules/Tests/EditStatus";
import { getSelectedId, handleSort, insert, sortOnFetch } from "../../../ui/helpers";
import { getOrganisationTests, getOrganisationTestsAmount } from "../../modules/Organisations/_api";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { info } from "../../../helpers/toasts";

export const OrganisationTests = ({ organisationId, tenants = [] }) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("desc");
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchTests = () => {
    setLoading(true);
    request(getOrganisationTests, organisationId, page, pageSize)
      .then((data) => {
        if (!data) return;
        return sortOnFetch(modifyTestsArray(data, tenants), field, direction);
      })
      .then((data) => data && setData(data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    request(getOrganisationTestsAmount, organisationId).then(
      (data) => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, []);

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton
          label="Change Status"
          disabled={archiveDisabled}
          onClick={handleStatusChange}
          data-testid="status-change"
        />
      </div>
    </>
  );

  function renderHeaderWithSorting(headings, data = []) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="text-nowrap"
        onClick={() => {
          handleSort(data, key, field, direction, setDirection);
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const archiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const handleStatusChange = () => {
    setModalOpen(true);
  };

  const statusMap = {
    completed: "result_date",
    received: "receive_date",
    pending_results: "dispatch_date",
    submitted: "submit_date",
  };

  const getSelectedStatus = (data.find(({ id }) => id === getSelectedId(selected)) || {}).status;

  const handleSubmitStatus = (values, { setSubmitting }) => {
    const testId = getSelectedId(selected);
    const payload = { status: values.status };
    payload[statusMap[values.status]] = values.date;

    request(modifyTestRequest, payload, testId)
      .then((test) => {
        if (!test) return;
        const index = data.findIndex((item) => (item || {}).id === (test || {}).id);
        handleModalClose();
        setData((state) => insert(state, index, modifyTestObject(test, tenants)));
        info("Status has been changed!");
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="row justify-content-center">
      {modalOpen && (
        <Modal
          maxWidth="sm"
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={
            <EditStatus
              onClose={handleModalClose}
              handleSubmit={handleSubmitStatus}
              status={getSelectedStatus}
            />
          }
        />
      )}
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Tests" renderButtons={renderButtons} />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderTestsRow}
            selected={selected}
            setSelected={setSelected}
            selectable
            link="tests"
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isServerPagination
            loading={loading}
            headerClassName="nospaces-header"
            renderHeaderWithSorting={renderHeaderWithSorting}
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
};
