import config from "react-global-configuration";

export const getPriceLists = () =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists`, {
    mode: "cors",
    credentials: "include",
  });

export const createPriceList = (price_list_data) =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(price_list_data),
    mode: "cors",
    credentials: "include",
  });

export const updatePriceList = (price_list_id, price_list_data) =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists/${price_list_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(price_list_data),
    mode: "cors",
    credentials: "include",
  });

export const deletePriceList = (price_list_id) =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists/${price_list_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const addPrice = (price_data) =>
  fetch(`${config.get("ApiUrl").Rest}/prices`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(price_data),
    mode: "cors",
    credentials: "include",
  });

export const getPrices = (price_list_id) =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists/${price_list_id}/prices`, {
    mode: "cors",
    credentials: "include",
  });

export const getPriceListById = (price_list_id) =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists/${price_list_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyPrice = (data, price_id) =>
  fetch(`${config.get("ApiUrl").Rest}/prices/${price_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archivePrice = (price_id) =>
  fetch(`${config.get("ApiUrl").Rest}/prices/${price_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getPriceById = (price_id) =>
  fetch(`${config.get("ApiUrl").Rest}/prices/${price_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const getPriceListTenants = (price_id) =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists/${price_id}/tenants`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyPriceListTenants = (price_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/price_lists/${price_id}/tenants`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
