import { LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import { info } from "../../../../helpers/toasts";
import { useFetch } from "../../../../hooks/fetch.hook";
import { ListButton } from "../../../../ui/components/ListButton";
import { shareResult, shareAllResults } from "../_api";
import { InviteUser } from "./InviteUser";

export const ShareResult = ({
  idsList = {},
  shareType = "",
  handleClose,
  shareRequest = "",
  enteredEmail = "",
  result = {},
}) => {
  const { organisation_ids = [], user_ids = [] } = idsList;
  const recipientsAmount = [...organisation_ids, ...user_ids].length;

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const { request } = useFetch();

  const share = () => {
    if (shareRequest === "single") return shareResult;
    else if (shareRequest === "multiple") return shareAllResults;
    else return;
  };

  const getSharedId = () => {
    if (shareRequest === "single") return result.id;
    else if (shareRequest === "multiple") return result.biller_org_id;
    else return;
  };
  const handleInvite = (values, { setSubmitting }) => {
    const payload = {
      role: shareType,
      ...values,
    };
    setLoading(true);
    setSubmitting(true);
    request(share(), payload, getSharedId())
      .then((data) => {
        if (!data) return;
        handleClose();
        info("User has been invited to the portal!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleShare = () => {
    const payload = {
      role: shareType,
      organisation_ids,
      user_ids,
    };
    setLoading(true);
    setSubmitting(true);
    request(share(), payload, getSharedId())
      .then((data) => {
        if (!data) return;
        handleClose();
        info("Results have been shared!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const renderContent = () => {
    if (!recipientsAmount) {
      return (
        <InviteUser
          handleSubmit={handleInvite}
          handleClose={handleClose}
          enteredEmail={enteredEmail}
          loading={loading}
        />
      );
    } else if (recipientsAmount > 1) {
      return (
        <div>
          <h4 className="text-center mb-5">
            {`Multiple recipients (${recipientsAmount}) found with entered email. Share with everyone?`}
          </h4>
          <div className="d-flex justify-content-center mt-10">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="medium"
                onClick={handleClose}
                data-testid="cancel"
                variant="outlined"
                boxShadow={false}
                text="#407A28"
              />
            </div>
            <ListButton
              label="Share"
              size="medium"
              onClick={handleShare}
              boxShadow={false}
              disabled={submitting}
              data-testid="submit"
            />
          </div>
          <div className="py-2">{loading && <LinearProgress />}</div>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="text-center mb-5">
            Email has been found
            <br /> Are you sure you want to share?
          </h4>
          <div className="d-flex justify-content-center mt-10">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="medium"
                onClick={handleClose}
                data-testid="cancel"
                variant="outlined"
                boxShadow={false}
                text="#407A28"
              />
            </div>
            <ListButton
              label="Share"
              size="medium"
              onClick={handleShare}
              boxShadow={false}
              disabled={submitting}
              data-testid="submit"
            />
          </div>
          <div className="py-2">{loading && <LinearProgress />}</div>
        </div>
      );
    }
  };
  return (
    <div className="p-4">
      <h3 className="text-center mb-5">Share Results</h3>
      {renderContent()}
    </div>
  );
};
