import React from "react";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { Formik } from "formik";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { ListButton } from "../../../../../ui/components/ListButton";
import { info } from "../../../../../helpers/toasts";
import { laborPriceListSchema } from "../../../../../helpers/schemas";

import {
  addLaboratoryTestsPrice,
  modifyLaboratoryTestsPrice,
  deleteLaboratoryTestsPrice,
} from "../_api";

const useStyles = makeStyles({
  edit: {
    padding: "2px",
    marginRight: 5,
    transition: "0.1s ease-out",
    background: "rgba(0,0,0,0)",
    "&:hover": {
      background: "rgba(0,0,0,0.03)",
    },
  },
  delete: {
    padding: "0 2px",
    color: "rgba(0,0,0,0.2)",
    transition: "0.1s ease-out",
    "&:hover": {
      color: "#e14352",
    },
  },
});

export const LaboratoryTestPrices = ({
  data = {},
  stateOptions,
  priceCodeOptions,
  usedStateList,
  laboratory_id,
  test_id,
  fetchPrices,
  updateTestsList,
  priceListLoading,
  setPriceListLoading,
}) => {
  const { request } = useFetch();
  const classes = useStyles();

  const initialValues = {
    tenant_id: data.tenant_id || "",
    price_list_id: data.price_list_id || "",
    laboratory_price: data.laboratory_price || "",
    fma_commission: data.fma_commission || "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setPriceListLoading(true);
    data.id
      ? request(
          modifyLaboratoryTestsPrice,
          laboratory_id,
          test_id,
          data.id,
          values
        )
          .then((data) => {
            if (data) {
              info("Price List had been updated!");
            }
          })
          .finally(() => {
            updateTestsList();
            fetchPrices();
            setSubmitting(false);
          })
      : request(addLaboratoryTestsPrice, laboratory_id, test_id, values)
          .then((data) => {
            if (data) {
              info("Price List had been created!");
            }
          })
          .finally(() => {
            updateTestsList();
            fetchPrices();
            setSubmitting(false);
            setPriceListLoading(false);
          });
  };

  const handleDelete = () => {
    setPriceListLoading(true);
    request(deleteLaboratoryTestsPrice, laboratory_id, test_id, data.id)
      .then((data) => {
        if (data) {
          info("Price had been deleted!");
        }
      })
      .finally(() => {
        updateTestsList();
        fetchPrices();
        setPriceListLoading(false);
      });
  };

  const findDuplicate = (value) => {
    return usedStateList
      .filter((state) => state === data.tenant_id)
      .find((state) => String(state) === String(value));
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={laborPriceListSchema(findDuplicate)}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <div className="row w-100">
            <div className="col-2">
              <MappedInput label="Laboratory price" name="laboratory_price" />
            </div>
            <div className="col-2">
              <MappedInput label="FMA Commission" name="fma_commission" />
            </div>
            <div className="col-3">
              <MappedSelect
                label="Price List"
                name="price_list_id"
                data-testid="price_list_id"
                options={(priceCodeOptions.length && priceCodeOptions) || []}
              />
            </div>
            <div className="col-3">
              <MappedSelect
                label="Applies to"
                name="tenant_id"
                data-testid="tenant_id"
                options={(stateOptions.length && stateOptions) || []}
              />
            </div>
            <div className="col-2 mt-5">
              {data.id ? (
                <>
                  <Button
                    onClick={handleSubmit}
                    color="secondary"
                    className={classes.edit}
                    aria-label="edit"
                    disabled={isSubmitting || priceListLoading}
                  >
                    Save
                  </Button>
                  <IconButton
                    onClick={handleDelete}
                    aria-label="delete"
                    disabled={isSubmitting || priceListLoading}
                    className={classes.delete}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : (
                <ListButton
                  label="Create"
                  onClick={handleSubmit}
                  disabled={isSubmitting || priceListLoading}
                  type="submit"
                />
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
